import React, { useState, useEffect, Fragment, useRef } from "react";
import { Form, Input, Button, Modal, notification } from "antd";
import { json, useNavigate } from "react-router-dom";
import Position from "./library/Position";
import NewCompany from "./library/NewCompany";
import ShareHolder from "./library/ShareHolder";
import ContactInfo from "./library/ContactInfo";
import DatumUpload from "./library/DatumUpload";
import BankUserInfo from "./library/BankUserInfo";
import ContactPerson from "./library/ContactPerson";
import TestCompanyInfo from "./library/TestCompanyInfo";
import AmericanNewCompany from "./library/AmericanNewCompany";
import AccountOpeningMethod from "./library/AccountOpeningMethod";
import ShareHolderChange from "./library/ShareHolderChange";
import JobInfo from "./library/jobInfo";
import OneYearCheck from "./library/OneYearCheck";
import AnnualCompany from "./library/annualCompany";
import dayjs from "dayjs";
import { doBankAccount, getBankDetail, doRegisterStage } from "../../services/api/registerInfo.request";
import { getOrderList, getOrderDetail, getAnnualRegister, setAnnualBranch, getOrderDetailInfo } from "../../services/api/registerInfo.request";
import { uploadService, doRegisterAndOpen } from "../../services/api/registerInfo.request";
import "./index.css";
import _ from "lodash";
import moment from "moment";
const { Item } = Form;
// 15375986212 654321 小程序登录
const titleNameList = [
  "香港公司自助注册填写",
  "现有香港公司注册填写",
  "美国公司自助注册填写",
  "银行开户注册填写",
  "银行转秘书资料填写",
  "年审",
  "香港公司快速注册+开户填写",
  "英国公司自助注册填写",
  // '年审',
];
let timerType = null;
let fileKay = ["frontCertificateUrl", "backCertificateUrl", "handCertificateUrl", "frontCompanyLicense", "addressUrl", "addressFile", "frontPassportUrl"];
let fileKeyList = ['br', 'ci', 'ma', 'nari', 'license',]

const RegisterInfo = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [selectData, setSelectData] = useState({});

  const [HSBKstatus, setHSBKstatus] = useState(false);

  const [form] = Form.useForm();
  const accountDetail = useRef({});
  const RenderRegisterTitle = () => {
    return (
      <div
        style={{
          marginTop: "30px",
          marginLeft: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: "24px" }}>{titleNameList[selectData.type]}</h2>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          返回
        </Button>
      </div>
    );
  };

  //银行开户提交 
  const setBankAccount = async (values, stage, data) => {

    values.account.establishmentDate = values.account.establishmentDate ? new Date(values.account.establishmentDate).getTime() : ""
    for (const key in values.account) {
      let item = values.account[key];
      console.log(item && item[0] && item[0].originFileObj, 123)
      if (item && item[0] && item[0].originFileObj) {
        let formData = new FormData();
        formData.append("file", item[0].originFileObj);
        const url = await uploadService(formData);
        if (fileKeyList.includes(key)) {
          values.account[key] = url.data;
          data.account[key] = url.data;
        } else {
          values.account[key] = [url.data];
          data.account[key] = [url.data];
        }


      }

      if (fileKeyList.includes(key)) {
        if (item && item[0] && item[0].url && item.length === 1) {
          values.account[key] = item[0].url;
        }
      } else {
        if (item && item[0] && item[0].url && item.length === 1) {
          values.account[key] = [item[0].url];
        }

      }
      if (key === "directorFile") {
        for (let i = 0; i < values.account.directorFile.length; i++) {
          const itme = values.account.directorFile[i];
          for (const key in itme) {
            if (fileKay.includes(key)) {
              try {
                if (itme[key].length === 0) {
                  itme[key] = ""
                } else {
                  if (itme[key]?.[0]?.url) {
                    itme[key] = itme[key][0]?.url;
                  }
                  else {
                    let formData = new FormData();
                    formData.append("file", itme[key][0].originFileObj);
                    const url = await uploadService(formData);
                    itme[key] = url.data;
                  }
                }
              } catch (error) {
                notification.open({
                  message: "提示信息",
                  description: "数据异常",
                });
                throw error;
              }
            }

          }
        }
      }



      // values.account.br = values.account.br ? values.account.br[0] : ''
      // values.account.ci = values.account.ci ? values.account.ci[0] : ''
      // values.account.ci = values.account.ci ? values.account.ci[0] : ''
      // values.account.nari = values.account.ci ? values.account.nari[0] : ''

    }
    doBankAccount({
      orderId: values.orderId,
      type: values.type === 7 ? 2 : values.type,
      state: stage === "stage" ? 0 : 2,
      ...values.account,
    }).then((res) => {
      if (stage) {
        // form.setFieldsValue(values)
        initBank(values.account, values)
        notification.open({
          message: "提示信息",
          description: res.desc === '成功' ? '暂存成功' : "暂存失败！",
        });
      } else {
        notification.open({
          message: "提示信息",
          description: res.desc === '成功' ? '提交成功' : "提交失败！",
        });
      }
    });

    console.log(values)

    // form.setFieldsValue();

  }

  const initBank = (values, data) => {

    if (values.br) values.br = values.br ? [{ url: values.br }] : [];
    if (values.ci) values.ci = values.ci ? [{ url: values.ci }] : [];

    if (values.license) values.license = values.license ? [{ url: values.license }] : [];
    if (values.ma) values.ma = values.ma ? [{ url: values.ma }] : [];
    if (values.nari) values.nari = values.nari ? [{ url: values.nari }] : [];
    if (values.procurementDataOne)
      values.procurementDataOne = values.procurementDataOne.length
        ? values.procurementDataOne.map((item) => {
          return { url: item };
        })
        : [];
    if (values.salesMaterialsOne)
      values.salesMaterialsOne = values.salesMaterialsOne.length
        ? values.salesMaterialsOne.map((item) => {
          return { url: item };
        })
        : [];
    if (values.bankStatement)
      values.bankStatement = values.bankStatement.length
        ? values.bankStatement.map((item) => {
          return { url: item };
        })
        : [];
    if (values.changeDocument)
      values.changeDocument = values.changeDocument.length
        ? values.changeDocument.map((item) => {
          return { url: item };
        })
        : [];
    if (values.procurementData)
      values.procurementData = values.procurementData.length
        ? values.procurementData.map((item) => {
          return { url: item };
        })
        : [];
    if (values.report)
      values.report = values.report.length
        ? values.report.map((item) => {
          return { url: item };
        })
        : [];
    if (values.salesMaterials)
      values.salesMaterials = values.salesMaterials.length
        ? values.salesMaterials.map((item) => {
          return { url: item };
        })
        : [];
    if (values.turnover)
      values.turnover = values.turnover.length
        ? values.turnover.map((item) => {
          return { url: item };
        })
        : [];
    if (values.directorFile)


      _.forEach(values.directorFile, (item) => {
        item.backCertificateUrl = item.backCertificateUrl ? [{ url: item.backCertificateUrl }] : [];
        item.frontCertificateUrl = item.frontCertificateUrl ? [{ url: item.frontCertificateUrl }] : [];
        item.frontPassportUrl = item.frontPassportUrl ? [{ url: item.frontPassportUrl }] : [];
      });



    if (values.establishmentDate) {
      values.establishmentDate = dayjs(parseInt(values.establishmentDate));
    }
    if (values.changeDataBos?.length) {
      values.changeDataFile = [{ url: values.changeDataBos[0].changeData[0] }];
    } else {
      values.changeDataFile = [];
    }
    if (values.declarationForm?.length) {
      values.declarationForm = values.declarationForm.map((item) => {
        return { url: item };
      });
    }
    if (values.companyId) {
      values.capitalChange = true;
    } else {
      values.capitalChange = values.capitalChange == 1 ? true : false;
    }
    if (values.changes)
      _.forEach(values.changes, (item) => {
        item.transferDate = item.transferDate ? dayjs(item.transferDate) : "";
      });
    if (data.type === 3) {
      values = { account: values, type: data.type, orderId: data.orderId };
    }
    form.setFieldsValue(values);
  }

  const onFinish = async (data, status) => {

    const values = _.cloneDeep(data);

    if (selectData.type === 3) {

      setBankAccount(values, status, data)
      return false
    }

    // try {
    if ([0, 1, 2, 6, 7].includes(selectData.type)) {
      values.register.registerCapital = values.register.registerCapital * 100;
      values.register.perAmount = values.register.perAmount * 100;
      if ([0, 1, 6].includes(selectData.type) && typeof values.register !== "string" && values.register.addressMode === 1) {
        values.register.secretaryAddress = values.register.secretaryAddress.join("|");
      }

      if ([2, 7].includes(selectData.type)) {
        values.register.usCompanyNameList = values.register.companyNames.map((item, index) => {
          return item + " " + values.register.companyends[index];
        });
      }

      for (let i = 0; i < values.register.peopleList.length; i++) {
        values.register.peopleList[i].birthDate = values.register.peopleList[i].birthDate
          ? dayjs(values.register.peopleList[i].birthDate).format("YYYY-MM-DD")
          : "";

        if ([0, 1, 6].includes(selectData.type) && values.register.peopleList[i].address)
          values.register.peopleList[i].address = values.register.peopleList[i].address.join("|");

        const itme = values.register.peopleList[i];
        for (const key in itme) {
          if (fileKay.includes(key)) {
            try {
              if (itme[key][0]?.url) {
                itme[key] = itme[key][0].url;
              } else if (itme[key][0]?.originFileObj) {
                let formData = new FormData();
                formData.append("file", itme[key][0].originFileObj);
                const url = await uploadService(formData);
                itme[key] = url.data;
              } else {
                itme[key] = "";
              }
            } catch (error) {
              notification.open({
                message: "提示信息",
                description: "数据异常",
              });
              throw error;
            }
          }
        }
      }
      if (status !== "stage") {
        if (!shareholderVerification(values.register.peopleList)) return false; // 股东董事信息校验
        if (!shareProportionVerification(values.register.peopleList)) return false; // 自然人校验
        if (!peopleRepeatVerification(values.register.peopleList)) return false; // 成员重复校验
      }
      doRegisterStage({
        orderId: values.orderId,
        type: values.type === 7 ? 2 : values.type,
        ...values.register,
      }).then((res) => {
        if (res.code === 200) {
          notification.open({
            message: "提示信息",
            description: status ? "暂存成功！" : "提交成功！",
          });
        } else {
          notification.open({ message: "提示信息", description: res.desc });
        }
      });
    }




    if ([5].includes(selectData.type)) {
      values.register.registerCapital = values.register.registerCapital * 100;
      values.register.perAmount = values.register.perAmount * 100;
      values.register.registerAddress = values.register.registerAddress.join("|");

      values.register.establishmentDate = values.register.establishmentDate
        ? new Date(dayjs(values.register.establishmentDate).format("YYYY-MM-DD")).getTime()
        : "";
      for (const key in values.register) {
        if (["ci", "br", "declarationForm", "changeDataBos"].includes(key)) {
          try {
            if (values.register[key][0]?.url) {
              values.register[key] = values.register[key][0].url;
            } else if (values.register[key][0]?.originFileObj) {
              let formData = new FormData();
              formData.append("file", values.register[key][0].originFileObj);
              const url = await uploadService(formData);
              values.register[key] = url.data;
            } else {
              values.register[key] = "";
            }
          } catch (error) {
            notification.open({
              message: "提示信息",
              description: "数据异常",
            });
            throw error;
          }
        }
      }
      for (let i = 0; i < values.register.peopleList.length; i++) {
        values.register.peopleList[i].birthDate = values.register.peopleList[i].birthDate
          ? dayjs(values.register.peopleList[i].birthDate).format("YYYY-MM-DD")
          : "";
        if (values.register.peopleList[i].address) values.register.peopleList[i].registerAddress = values.register.peopleList[i].address;

        const itme = values.register.peopleList[i];
        for (const key in itme) {
          if (fileKay.includes(key)) {
            try {
              if (itme[key][0]?.url) {
                itme[key] = itme[key][0].url;
              } else if (itme[key][0]?.originFileObj) {
                let formData = new FormData();
                formData.append("file", itme[key][0].originFileObj);
                const url = await uploadService(formData);
                itme[key] = url.data;
              } else {
                itme[key] = "";
              }
            } catch (error) {
              notification.open({
                message: "提示信息",
                description: "数据异常",
              });
              throw error;
            }
          }
        }
      }

      values.register.capitalChange = values.register.capitalChange ? 1 : 0;
      for (let i = 0; i < values.register.changes.length; i++) {
        values.register[i].transferDate = values.register[i].transferDate ? dayjs(values.register.peopleList[i].transferDate).format("YYYY-MM-DD") : "";
      }

      values.register.contactName = values.register.urgentContactName;
      values.register.contactPhone = values.register.urgentContactPhone;
      values.register.contactEmail = values.register.urgentContactEmail;

      const saveData = await setAnnualBranch({
        orderId: values.orderId,
        type: values.type,
        ...values.register,
        state: 0,
      });
      if (saveData.code === 200) {
        notification.open({
          message: "提示信息",
          description: "保存成功！",
        });
      }

    }
  };
  const shareholderVerification = (data) => {
    let hasShareholder = false;
    for (let i in data) {
      if (data[i].shareholderType === 1 && data[i].position !== 1) {
        hasShareholder = true;
        break;
      }
    }
    if (!hasShareholder)
      notification.open({
        message: "提示信息",
        description: "新注册公司至少需要有一位自然人董事！",
      });
    return hasShareholder;
  };

  const shareProportionVerification = (data) => {
    let num = 0;
    for (let i in data) {
      if (data[i].position != 2) {
        num += Number(data[i].shareProportion);
      }
    }
    if (num < 100) {
      notification.open({
        message: "提示信息",
        description: "股东董事占股比例总和需达到100%，请检查！",
      });
      return false;
    } else if (num > 100) {
      notification.open({
        message: "提示信息",
        description: "股东董事占股比例总和不得超过100%，请检查！",
      });
      return false;
    }
    return true;
  };

  const peopleRepeatVerification = (data) => {
    for (var i = 0; i < data.length - 1; i++) {
      for (var j = i + 1; j < data.length; j++) {
        if (data[i].certificateNum === data[j].certificateNum) {
          notification.open({
            message: "提示信息",
            description: `成员${i + 1}和成员${j + 1}不得为相同成员，请检查！`,
          });
          return false;
        }
      }
    }
    return true;
  };





  const doOrderList = async () => {
    const company = await getOrderList({
      progress: 0,
      queryValue: "",
      type: null,
      pageNum: 1,
      pageSize: 100,
    });
    if (company.code === 200) {
      const stepDate = [];
      company.data.list.forEach((v) => {
        if ((v.step === 2 && (v.registeredCompanyName || v.registeredCompanyName !== "未选择公司")) || (v.status === 12 && v.containOpenAccount)) {
          stepDate.push({ ...v });
        }
      });
      setOrderList(stepDate);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
    doOrderList();
  }, []);

  const goToRegister = (data) => {

    let detail = null;
    let orderType = null
    if (data.type === 0) detail = getOrderDetail;
    if (data.type === 1) detail = getOrderDetail;
    if (data.type === 2) detail = getOrderDetail;
    if (data.type === 3) orderType = getOrderDetailInfo;
    if (data.type === 3) detail = getBankDetail;
    if (data.type === 6) detail = getOrderDetail;
    if (data.type === 5) detail = getAnnualRegister;
    if (data.type === 7) detail = getOrderDetail;

    // if (data.type === 5) detail = postRegisterAndOpen;
    if (orderType) {
      orderType(data.id).then(async (res) => {
        setHSBKstatus(res.data.skipBankMatching === 1 ? true : false)
      })
    }

    if (detail) {
      detail(data.id).then(async (res) => {
        timerType = data.type;
        setIsModalOpen(false);
        let values = res.data || {};
        // if (data.type === 5 || data.type === 6) {
        //   values = { ...res.data, ...res.data.account, ...res.data.registerVo }
        //   accountDetail.current = res.data.account?res.data.account.detail:{};
        // }
        // 注册资本
        if (values.registerCapital) {
          values.registerCapital = Number(values.registerCapital) / 100;
        } else {
          if (data.type === 0 || data.type === 1 || data.type === 6) {
            values.registerCapital = 10000;
          }
        }
        // 每股金额
        if (values.perAmount) {
          values.perAmount = Number(values.perAmount) / 100;
        } else {
          if (data.type === 0 || data.type === 1 || data.type === 6) {
            values.perAmount = 1;
          }
        }
        if (values.registerCapital && values.perAmount) {
          // 公司股份总数
          values.allCapital = values.registerCapital / values.perAmount;
        }

        if ([0, 1, 6].includes(data.type)) {
          if (values.addressMode === 1) {
            values.secretaryAddress = values.secretaryAddress ? values.secretaryAddress.split("|") : [];
          }
        }
        if ([2, 7].includes(data.type)) {
          const companyNameList = res.data.usCompanyNameList || [];

          let companyNames = [],
            companyends = [];
          companyNameList.forEach((name) => {
            const names = name.split(" ");
            companyNames.push(names.slice(0, names.length - 1).join(" "));
            companyends.push(names.slice(names.length - 1).join(" "));
          });
          values.companyNames = companyNames;
          values.companyends = companyends;
        } else if ([5].includes(data.type)) {
          if (values.companyId) {
            await getOrderDetail(values.companyId).then((companyRes) => {
              values.companyInfo = companyRes.data;
              values.peopleList = companyRes.data.peopleList;
            });
            values.urgentContactName = values.contactName;
            values.urgentContactPhone = values.contactPhone;
            values.urgentContactEmail = values.contactEmail;
            values.changeList = [];
            if (values.changeDataBos) {
              if (values.changeDataBos[0].changeInfo) {
                values.changeList.push(1);
              }
              if (values.changeDataBos[1].changeInfo) {
                values.changeList.push(2);
              }
              if (values.changeDataBos[2].changeInfo) {
                values.changeList.push(3);
              }
              if (values.changeDataBos[3].changeInfo) {
                values.changeList.push(4);
              }
              if (values.changeDataBos[4].changeInfo) {
                values.changeList.push(5);
              }
              if (values.changeDataBos[5].changeInfo) {
                values.changeList.push(6);
              }
              if (values.changeDataBos[6].changeInfo) {
                values.changeList.push(7);
              }
            } else {
              values.changeDataBos = [
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, //0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
                {
                  changeType: 0, // 0图片  1文件
                  changeInfo: "",
                  changeData: [],
                },
              ];
            }
          }
        }

        if (!values?.peopleList?.length) values.peopleList = [{ shareholderType: 1 }];
        if (values.peopleList)
          _.forEach(values.peopleList, (item) => {
            item.frontCertificateUrl = item.frontCertificateUrl ? [{ url: item.frontCertificateUrl }] : [];
            item.backCertificateUrl = item.backCertificateUrl ? [{ url: item.backCertificateUrl }] : [];
            item.handCertificateUrl = item.handCertificateUrl ? [{ url: item.handCertificateUrl }] : [];
            item.frontCompanyLicense = item.frontCompanyLicense ? [{ url: item.frontCompanyLicense }] : [];
            item.addressUrl = item.addressUrl ? [{ url: item.addressUrl }] : [];
            item.addressFile = item.addressFile ? [{ url: item.addressFile }] : [];
            item.birthDate = item.birthDate ? dayjs(item.birthDate) : "";
            item.addressType = item.addressFile.length ? 1 : 0;
            item.licenseType = Number(item.licenseType) || 0;
            if ([0, 1, 6].includes(data.type)) item.address = item.address ? item.address.split("|") : [];
            if (item.registerAddress) item.address = item.registerAddress;
          });

        if (values.br) values.br = values.br ? [{ url: values.br }] : [];
        if (values.ci) values.ci = values.ci ? [{ url: values.ci }] : [];

        if (values.license) values.license = values.license ? [{ url: values.license }] : [];
        if (values.ma) values.ma = values.ma ? [{ url: values.ma }] : [];
        if (values.nari) values.nari = values.nari ? [{ url: values.nari }] : [];
        if (values.procurementDataOne)
          values.procurementDataOne = values.procurementDataOne.length
            ? values.procurementDataOne.map((item) => {
              return { url: item };
            })
            : [];
        if (values.salesMaterialsOne)
          values.salesMaterialsOne = values.salesMaterialsOne.length
            ? values.salesMaterialsOne.map((item) => {
              return { url: item };
            })
            : [];
        if (values.bankStatement)
          values.bankStatement = values.bankStatement.length
            ? values.bankStatement.map((item) => {
              return { url: item };
            })
            : [];
        if (values.changeDocument)
          values.changeDocument = values.changeDocument.length
            ? values.changeDocument.map((item) => {
              return { url: item };
            })
            : [];
        if (values.procurementData)
          values.procurementData = values.procurementData.length
            ? values.procurementData.map((item) => {
              return { url: item };
            })
            : [];
        if (values.report)
          values.report = values.report.length
            ? values.report.map((item) => {
              return { url: item };
            })
            : [];
        if (values.salesMaterials)
          values.salesMaterials = values.salesMaterials.length
            ? values.salesMaterials.map((item) => {
              return { url: item };
            })
            : [];
        if (values.turnover)
          values.turnover = values.turnover.length
            ? values.turnover.map((item) => {
              return { url: item };
            })
            : [];
        if (values.directorFile)


          _.forEach(values.directorFile, (item) => {
            item.backCertificateUrl = item.backCertificateUrl ? [{ url: item.backCertificateUrl }] : [];
            item.frontCertificateUrl = item.frontCertificateUrl ? [{ url: item.frontCertificateUrl }] : [];
            item.frontPassportUrl = item.frontPassportUrl ? [{ url: item.frontPassportUrl }] : [];
          });



        if (values.establishmentDate) {
          values.establishmentDate = dayjs(parseInt(values.establishmentDate));
        }
        if (values.changeDataBos?.length) {
          values.changeDataFile = [{ url: values.changeDataBos[0].changeData[0] }];
        } else {
          values.changeDataFile = [];
        }
        if (values.declarationForm?.length) {
          values.declarationForm = values.declarationForm.map((item) => {
            return { url: item };
          });
        }
        if (values.companyId) {
          values.capitalChange = true;
        } else {
          values.capitalChange = values.capitalChange == 1 ? true : false;
        }
        if (values.changes)
          _.forEach(values.changes, (item) => {
            item.transferDate = item.transferDate ? dayjs(item.transferDate) : "";
          });
        if (data.type === 3) {
          values = { account: values, type: data.type, orderId: data.id };
        } else {
          values = { register: values, type: data.type, orderId: data.id };
        }
        form.setFieldsValue(values);

        console.log(values)
        setSelectData(values);
      });
    }
  };

  function doLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  function stage(e, state) {
    console.log(state)
    if (state) {
      onFinish(state, 'stage')
    } else {
      console.log(form.getFieldsValue())
      onFinish(form.getFieldsValue(), "stage");
    }
  }

  return (
    <div>
      <Modal closable={false} keyboard={false} maskClosable={false} title="请选需要完善的注册申请" footer={null} open={isModalOpen} className="my-custom-class">
        <div style={{ maxHeight: "520px", overflow: "auto" }}>
          {orderList.map((item, index) => {
            return (
              <div
                key={index}
                className="order_card"
                onClick={() => {
                  goToRegister(item);
                }}
              >
                <div className="order_main">
                  <div className="order_inner_flex">
                    <div className="order_inner_upper">{item.registeredCompanyName || "待填写资料"}</div>
                    <div className="order_inner_upper">{item.planName}</div>
                  </div>

                  <div className="order_inner_sub">
                    <span>订单号：</span>
                    {item.orderNo}
                  </div>
                  <div className="order_inner_sub">
                    <span>申请时间：</span> {moment(Number(item.createTime)).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ textAlign: "right", marginTop: 8 }}>
          <Button type="primary" onClick={doLogout}>
            退出登录
          </Button>
        </div>
      </Modal>
      {isModalOpen ? null : (
        <div
          style={{
            padding: "20px 30px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "1210px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              padding: "10px 25px",
            }}
          >
            <div className="module_title">
              <RenderRegisterTitle />
            </div>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
              <Item hidden name="orderId">
                <Input />
              </Item>
              <Item hidden name="type">
                <Input />
              </Item>
              {/**香港公司自助注册填写 */}
              {selectData.type === 0 || selectData.type === 6 ? (
                <React.Fragment>
                  <NewCompany data={selectData.register} type={selectData.type} formDom={form} stage={stage} />
                  <ShareHolder formDom={form} type={selectData.type} stage={stage} />
                  {/* <ContactPerson formDom={form} stage={stage} /> */}
                </React.Fragment>
              ) : null}
              {/**现有香港公司注册填写 */}
              {selectData.type === 1 ? (
                <React.Fragment>
                  <NewCompany data={selectData.register} type={selectData.type} formDom={form} stage={stage} />
                  <ShareHolder formDom={form} type={selectData.type} stage={stage} />
                  {/* <ContactPerson formDom={form} stage={stage} /> */}
                </React.Fragment>
              ) : null}
              {/**美国公司自助注册填写 */}
              {selectData.type === 2 || selectData.type === 7 ? (
                <React.Fragment>
                  <AmericanNewCompany data={selectData.register} type={selectData.type} formDom={form} stage={stage} />
                  <ShareHolder formDom={form} type={selectData.type} stage={stage} />
                  {/* <Position formDom={form} stage={stage} /> */}
                  {/* <ContactPerson formDom={form} stage={stage} /> */}
                </React.Fragment>
              ) : null}
              {/**银行开户 */}
              {selectData.type === 3 ? (
                <React.Fragment>
                  <TestCompanyInfo isHkType={HSBKstatus} stage={stage} formDom={form} />
                  <BankUserInfo stage={stage} formDom={form} />
                  <DatumUpload stage={stage} formDom={form} type={selectData.type} />
                  <ContactInfo stage={stage} formDom={form} />
                  {/* <AccountOpeningMethod stage={stage} /> */}
                </React.Fragment>
              ) : null}
              {/* 年审 */}
              {selectData.type === 5 ? (
                <React.Fragment>
                  {!selectData.register.companyId ? (
                    <>
                      <AnnualCompany formDom={form} data={selectData} stage={stage} />
                      <ShareHolder formDom={form} type={selectData.type} stage={stage} />
                      <ShareHolderChange formDom={form} stage={stage} type="radio" />
                      <ContactPerson formDom={form} stage={stage} />
                    </>
                  ) : (
                    <OneYearCheck formDom={form} stage={stage} selectData={selectData} />
                  )}
                </React.Fragment>
              ) : null}
              {/**香港公司注册+开户填写 */}
              {
                // selectData.type === 5 || selectData.type === 6 ? <React.Fragment>
                //   <NewCompany data={selectData.register} type={selectData.type} formDom={form} stage={stage} />
                //   <ShareHolder formDom={form} stage={stage} />
                //   <DatumUpload formDom={form} type={selectData.type} />
                //   {/* <ContactInfo /> */}
                //   {/* <AccountOpeningMethod /> */}
                // </React.Fragment> : null
              }
              {/* {
            selectData.type === 7 && <Fragment>
              <YearCheck form={form} />
              <ShareHolder formDom={form} type={selectData.type} stage={stage} />
              <ShareHolderChange form={form} type="radio" />
              <JobInfo />
            </Fragment>
          }
          {
            selectData.type === 8 && <Fragment>
              <OneYearCheck form={form} />
            </Fragment>
          } */}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "50px",
                }}
              >
                <Button type="primary" htmlType="submit" size="large" className="button_sty_set">
                  提交
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};
export default RegisterInfo;
