import axios from "axios";

// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://yshd.esailing.cn/v1' : 'https://yshd.esailing.cn/v1';
axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "https://yshd.esailing.cn/v1" : "https://yshd.esailing.cn/v1";
const request = axios.create({
  timeout: 600000,
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["token"] = token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
request.interceptors.response.use(
  (response) => {
    if (response) {
      if (response.request.responseType === "blob" || response.code === 200 || response.code === 0) {
        // 下载接口，直接返回
        return response.data;
      }
      return response.data;
    }
  },
  (err) => {
    if (err.response.status) {
      //在这对错误返回状态值进行处理
      return Promise.reject(err.response.status);
    }
  }
);

export default request;
