import React, { Fragment, useEffect } from "react";
import {
  Form,
  Switch,
  Row,
  Col,
  Input,
  InputNumber,
  DatePicker,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import OtherChange from "../component/otherChange";
import "../index.css";

const { Item, List } = Form;
const ShareHolderChange = (props) => {
  const { formDom, stage, type } = props; // type:'',只编辑股东信息；type:'radio',可选择股东是否变更
  const capitalChange = Form.useWatch(["register", "capitalChange"], formDom);
  return (
    <Fragment>
      <Item
        hidden={!type}
        label="股东是否有变更"
        name={["register", "capitalChange"]}
        rules={[{ required: true, message: "请选择" }]}
      >
        <Switch value={capitalChange} />
      </Item>
      {capitalChange && (
        <List name={["register", "changes"]}>
          {(fields, { add, remove }) => (
            <Fragment>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment>
                  <Row className="userInfo" key={key}>
                    <Col span={2} className="userNo">
                      <span>股东变更{key + 1}</span>
                    </Col>
                    <Col span={22} className="userDetails">
                      <Row>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="股份受让方"
                            name={[name, "transfereeName"]}
                            wrapperCol={{ span: 14 }}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <Input placeholder="请输入" onBlur={stage} />
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="受让方现持有股份数"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            name={[name, "transfereeCapital"]}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: "100%" }}
                              onBlur={stage}
                            />
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="股份出让方"
                            name={[name, "transferorName"]}
                            wrapperCol={{ span: 14 }}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <Input placeholder="请输入" onBlur={stage} />
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="出让方现持有股份数"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            name={[name, "transferorCapital"]}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: "100%" }}
                              onBlur={stage}
                            />
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="转让股份数"
                            name={[name, "transferCapital"]}
                            wrapperCol={{ span: 14 }}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: "100%" }}
                              onBlur={stage}
                            />
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Item
                            {...restField}
                            label="股份转让日期"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            name={[name, "transferDate"]}
                            rules={[{ required: true, message: "请输入" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              onChange={stage}
                            />
                          </Item>
                        </Col>
                        {!capitalChange && (
                          <Col span={12}>
                            <OtherChange form={formDom} />
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {fields.length > 1 ? (
                      <Button
                        className="deleteStyle"
                        danger
                        type="link"
                        onClick={() => remove(name)}
                      >
                        删除
                      </Button>
                    ) : null}
                  </Row>
                </Fragment>
              ))}
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="link"
                  onClick={() => add({}, fields.length)}
                  icon={<PlusOutlined />}
                >
                  添加
                </Button>
              </Col>
            </Fragment>
          )}
        </List>
      )}
    </Fragment>
  );
};

export default ShareHolderChange;
