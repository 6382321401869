import React, { Fragment, useState } from 'react';
import { DatePickerProps } from 'antd';
import { Row, Col, Form, Input, DatePicker, InputNumber, Button, Select, Divider, Upload, Image as Img, notification, } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import "../index.css"
import _, { set } from 'lodash';
import { uploadServiceIdentity, requestToTpy, uploadService } from '../../../services/api/registerInfo.request';
import moment from 'moment';
const { Item, List } = Form;

const frontCertificateUrlImg = "https://minio.esailing.cn/gs-file/2023-04-25/0e14fc7bf6924a8ea9a56b139f1776e8/20230425-135135.png"
const backCertificateUrlImg = "https://minio.esailing.cn/gs-file/2023-04-25/4477d2b1cbee4188827dfa3bedfba36e/20230425-135154.png"
const frontCompanyLicenseUrlImg = "https://scrm-product-1253467224.cos.ap-beijing.myqcloud.com/2540473940758812/2023/04/18/6bf273a1-610e-4328-8561-f773bce0897a.png"
const shareholderTypeMap = [
  { value: 0, label: '法人' },
  { value: 1, label: '自然人' },
];
const addressTypeMap = [
  { value: 0, label: '图片' },
  { value: 1, label: 'pdf' },
];
const certificateTypes = [
  { value: 0, label: '身份证' },
  { value: 1, label: '护照' },
  { value: 2, label: '外国护照' },
];

const positionTypeMap = [
  { value: 0, label: '股东+董事' },
  { value: 1, label: '股东' },
  { value: 2, label: '董事' },
];

const companyTypeMap = [
  { value: 0, label: '普通国内公司 ' },
  { value: 1, label: '国企' },
  { value: 2, label: '国内上市' },
  { value: 3, label: '香港上市' },
];
const licenseTypeMap = [
  { value: 0, label: '国内执照' },
  { value: 1, label: '海外执照' },
]
const ShareHolder = ({ formDom, type, stage }) => {

  const shareholderTypeWatch = Form.useWatch(['register', 'peopleList'], formDom);
  const propsfile = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  }
  const propspdf = {
    name: 'file',
    accept: '.pdf',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  }
  const propsfileIdentity = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false
    }
  }
  const disabledDate = (_current) => {
    let current = _current.format('YYYY-MM-DD')
    return current && current > moment().endOf('day').format('YYYY-MM-DD')
  }
  const dateChange = (date, dateString) => {
    console.log(date, dateString);
  }
  const getBirthDate = (date) => {
    var Y = date.slice(0, 4);
    var M = date.slice(4, 6);
    var D = date.slice(6, 8);
    return Y + "-" + M + "-" + D;
  }
  const fileChange = async (file, index, type, key) => {

    console.log(type)
    if (file.fileList.length === 0) return
    let formData = new FormData();
    formData.append('file', file.file);
    formData.append('type', type);
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch));
    const idendityList = ['idCardFront', 'passport', 'idCardBack', 'license'];
    const fn = idendityList.includes(type) ? uploadServiceIdentity : uploadService;
    const res = await fn(formData);
    if (res.code === 200) {
      let surRes
      let otherRes
      switch (type) {
        case "idCardFront": // 身份证人像面
          surRes = await requestToTpy(res.data.name.slice(0, 1))
          otherRes = await requestToTpy(res.data.name.slice(1, 3))
          peopleList[index] = {
            ...peopleList[index],
            [key]: [{ url: res.data.imageUrl }],
            name: res.data.name,
            certificateNum: res.data.certificateNum,
            birthDate: moment(getBirthDate(res.data.birthDate)),
            surname: surRes.data.toUpperCase(),
            otherNames: otherRes.data.toUpperCase(),
          }
          break;
        case "passport": // 国内护照
          surRes = await requestToTpy(res.data.name.slice(0, 1))
          otherRes = await requestToTpy(res.data.name.slice(1, 3))
          peopleList[index] = {
            ...peopleList[index],
            [key]: [{ url: res.data.imageUrl }],
            name: res.data.name,
            certificateNum: res.data.certificateNum,
            birthDate: moment(getBirthDate(res.data.birthDate)),
            surname: surRes.data.toUpperCase(),
            otherNames: otherRes.data.toUpperCase(),
          }
          break;
        case "idCardBack": // 身份证国徽面
          peopleList[index] = {
            ...peopleList[index],
            birthDate: peopleList[index].birthDate ? moment(peopleList[index].birthDate) : peopleList[index].birthDate,
            [key]: [{ url: res.data.imageUrl }],
          }
          break;
        case "license": // 国内营业执照
          peopleList[index] = {
            ...peopleList[index],
            [key]: [{ url: res.data.imageUrl }],
            companyName: res.data.companyName,
            companyNo: res.data.creditCode,
            registerAddress: res.data.address,
          }
          break;
        default:
          peopleList[index] = {
            ...peopleList[index],
            [key]: [{ url: res.data }],
            birthDate: peopleList[index].birthDate ? moment(peopleList[index].birthDate) : peopleList[index].birthDate,
          }
      }
    } else {
      notification.open({ message: '提示信息', description: res.desc });
    }
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
  }
  const fileRemove = (index, type) => {
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch))
    if (type === 'backCertificateUrl') {
      peopleList[index] = {
        ...peopleList[index],
        [type]: [],
        birthDate: peopleList[index].birthDate ? moment(peopleList[index].birthDate) : peopleList[index].birthDate,
      }
    } else {
      peopleList[index] = {
        ...peopleList[index],
        [type]: [],
        name: '',
        certificateNum: '',
        birthDate: '',
        surname: '',
        otherNames: '',
      }
    }
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
  }
  const normFile = (e) => {
    if (Array.isArray(e)) return e
    return e && e.fileList;
  }
  const changeAddressType = (e, index) => {
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch))
    peopleList[index] = {
      ...peopleList[index],
      addressType: e,
      addressFile: [],
      addressUrl: [],
      birthDate: peopleList[index].birthDate ? moment(peopleList[index].birthDate) : null,
    };
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
  }

  const shareholderTypeChange = (e, index) => {
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch))
    peopleList[index] = {
      ...peopleList[index],
      shareholderType: e,
      position: e === 0 ? 1 : 0
    }
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
  }

  const changeCertificateTypes = (e, index) => {
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch))
    peopleList[index] = {
      ...peopleList[index],
      frontCertificateUrl: [],
      backCertificateUrl: [],
      handCertificateUrl: [],
      certificateType: e,
      name: '',
      certificateNum: '',
      birthDate: '',
      surname: '',
      otherNames: '',
      nationality: e !== 2 ? '中国' : ''
    }
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
    // const registerInfo = formDom.getFieldValue('register');
    // formDom.setFieldValue('register',{
    //   ...registerInfo,
    //   peopleList
    // })
  }

  const licenseTypeMapChange = (e, index) => {
    const peopleList = JSON.parse(JSON.stringify(shareholderTypeWatch))
    peopleList[index] = {
      ...peopleList[index],
      licenseType: e,
      companyName: "",
      companyNo: "",
      registerAddress: ""
    };
    formDom.setFieldValue(['register', 'peopleList', index], peopleList[index])
    stage()
  }

  return (
    <div>
      <div className="title_des"><span style={{ fontSize: '20px' }}>股东/董事信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <div className='tips'>
        <p>温馨提示：</p>
        <p>1）姓 名：中国公民请用身份证名；境外人士请用护照名 </p>
        <p>2）占股比例：比例超过25%为公司重要控制人 </p>
        <p>3）董 事：新注册公司至少需要有一位自然人董事</p>
      </div>
      <List name={['register', 'peopleList']}>
        {(fields, { add, remove }) => {
          return <Fragment>
            {fields.map(({ key, name, ...restField }) => {
              const shareholderType = shareholderTypeWatch && shareholderTypeWatch[name]?.shareholderType;
              const hasNaturalPerson = shareholderTypeWatch && shareholderTypeWatch[name]?.shareholderType === 1;
              const shareholder = shareholderTypeWatch && shareholderTypeWatch[name]?.certificateType;
              const position = shareholderTypeWatch && shareholderTypeWatch[name]?.position;
              const hasPosition = position === 2;
              const hasIdCard = shareholder === 0;
              const hasPassport = shareholder === 1 || shareholder === 2;
              const certificateType = shareholderTypeWatch && shareholderTypeWatch[name]?.certificateType;
              const addressType = shareholderTypeWatch && shareholderTypeWatch[name]?.addressType;
              const licenseType = shareholderTypeWatch && shareholderTypeWatch[name]?.licenseType;
              return <Row className='userInfo' key={key}>
                <Col span={2} className='userNo'>
                  <span>成员{key + 1}</span>
                </Col>
                <Col span={22} className='userDetails'>
                  <Row key={key}>
                    <Col span={12}>
                      <div className='des_suggest'>
                        {
                          shareholderType === 1 ?
                            <span>年满18周岁以上</span> :
                            <span>以公司作为股东</span>
                        }
                      </div>
                      <Item {...restField} label="股东类型" name={[name, 'shareholderType']} rules={[{ required: true, message: '请选择股东类型' }]}>
                        <Select options={shareholderTypeMap} onChange={(e) => { shareholderTypeChange(e, key) }} />
                      </Item>
                    </Col>
                    {
                      hasNaturalPerson ? <Col span={12}>
                        <div className='des_suggest'>
                          系统目前支持中国大陆身份证，中国护照以及中国工商营业执照的识别
                        </div>
                        <Item {...restField} label="证件类型" name={[name, 'certificateType']} rules={[{ required: true, message: '请选择证件类型' }]}>
                          <Select options={certificateTypes} onChange={e => changeCertificateTypes(e, key)} />
                        </Item>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson && (type !== 2 || hasIdCard) ? <Col span={12}>
                        {certificateType !== 2 && <div className='des_suggest'>
                          请检查识别信息，如有误，需要重新上传清晰证件
                        </div>}
                        {certificateType === 0 && <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="证件正面照" name={[name, 'frontCertificateUrl']} rules={[{ required: true, message: '请上传证件正面照' }]}>
                          <Upload
                            {...propsfileIdentity}
                            onChange={file => fileChange(file, key, 'idCardFront', 'frontCertificateUrl')}
                            onRemove={() => fileRemove(key, 'frontCertificateUrl')}><Img src={frontCertificateUrlImg} preview={false} /></Upload>
                        </Item>}
                        {certificateType === 1 && <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="证件正面照" name={[name, 'frontCertificateUrl']} rules={[{ required: true, message: '请上传证件正面照' }]}>
                          <Upload {...propsfile}
                            onChange={file => fileChange(file, key, 'passport', 'frontCertificateUrl')}
                            onRemove={() => fileRemove(key, 'frontCertificateUrl')}
                          ><Button type='link'>上传国内护照</Button></Upload>
                        </Item>}

                        {certificateType === 2 && <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="证件正面照" name={[name, 'frontCertificateUrl']} rules={[{ required: true, message: '请上传证件正面照' }]}>
                          <Upload {...propsfile}
                            onChange={file => fileChange(file, key, null, 'frontCertificateUrl')}
                            onRemove={() => fileRemove(key, 'frontCertificateUrl')}
                          ><Button type='link'>上传国外护照</Button></Upload>
                        </Item>}


                      </Col> : null
                    }
                    {
                      hasNaturalPerson && (type !== 2 || hasIdCard) ? <Col span={12}>
                        {certificateType === 0 && <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="证件背面照" name={[name, 'backCertificateUrl']} rules={[{ required: true, message: '请上传证件背面照' }]}>
                          <Upload
                            {...propsfileIdentity}
                            onChange={file => fileChange(file, key, 'idCardBack', 'backCertificateUrl')}
                            onRemove={() => fileRemove(key, 'backCertificateUrl')}><Img src={backCertificateUrlImg} preview={false} /></Upload>
                        </Item>}
                      </Col> : null
                    }
                    {
                      hasNaturalPerson && (type !== 2 || !hasIdCard) ?
                        <Row>
                          <Col span={12}>
                            <Item  {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="手持证件照上传" name={[name, 'handCertificateUrl']} rules={[{ required: true, message: '请输入手持证件照上传' }]}>
                              <Upload
                                {...propsfile}
                                onChange={file => fileChange(file, key, null, 'handCertificateUrl')}
                                onRemove={() => fileRemove(key, 'handCertificateUrl')}
                              >
                                <Button type='link'>上传</Button>
                              </Upload>
                            </Item>
                          </Col>
                          <Col span={6}>
                            {
                              hasIdCard ? <img alt=''
                                style={{ width: '68%', height: 'auto', marginLeft: '-140px', marginBottom: '20px' }}
                                src='https://scrm-product-1253467224.cos.ap-beijing.myqcloud.com/2540473940758812/2023/04/18/9d4aa785-c62c-4e59-8d5e-44e7cc965b04.png'
                              /> : null
                            }
                            {
                              hasPassport ? <img alt=''
                                style={{ width: '94%', height: 'auto', marginLeft: '-140px', marginBottom: '20px' }}
                                src='https://scrm-product-1253467224.cos.ap-beijing.myqcloud.com/2540473940758812/2023/04/18/32a81b6a-7748-4812-b64b-9a36527de97b.png'
                              /> : null
                            }
                          </Col>
                        </Row>
                        : null
                    }
                    {
                      hasNaturalPerson && type !== 2 ? <Col span={12}>
                        <Item {...restField} label="地址证明" name={[name, 'addressType']} >
                          <Select options={addressTypeMap} onChange={e => changeAddressType(e, key)} />
                        </Item>
                        <div className='des_suggest' style={{ marginLeft: '28px', marginBottom: '6px' }}>
                          体现名字及地址的近三个月水或电或煤气或滴滴账单等
                        </div>
                        {
                          addressType === 0 ?
                            <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="地址证明图片" name={[name, 'addressUrl']} rules={[{ required: true, message: '请上传地址证明图片' }]}>
                              <Upload
                                {...propsfile}
                                onChange={file => fileChange(file, key, null, 'addressUrl')}
                                onRemove={() => fileRemove(key, 'addressUrl')}
                              >
                                <Button type='link'>上传</Button>
                              </Upload>
                            </Item>
                            :
                            <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="地址证明文件" name={[name, 'addressFile']} rules={[{ required: true, message: '请上传地址证明文件' }]}>
                              <Upload {...propspdf}
                                onChange={file => fileChange(file, key, null, 'addressFile')}
                                onRemove={() => fileRemove(key, 'addressFile')}>
                                <Button type='link'>上传</Button>
                              </Upload>
                            </Item>
                        }
                      </Col> : !hasIdCard ? <Col span={12} /> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={12}>


                        <Item {...restField} label="姓名" name={[name, 'name']} rules={[{ required: true, message: '请输入姓名' }]}>
                          <Input placeholder='请输入姓名' onBlur={stage} />
                        </Item>

                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={21}>
                        <Row>
                          <Col span={4}>
                            <Item {...restField} label="英文姓名(或拼音)" labelCol={{ span: 24 }}></Item>
                          </Col>
                          <Col span={4}>
                            <Item {...restField} label="姓" name={[name, 'surname']} rules={[{ required: true, message: '姓' }]}>
                              <Input placeholder="姓" onBlur={stage} />
                            </Item>
                          </Col>
                          <Col span={4}>
                            <Item {...restField} label="名" name={[name, 'otherNames']} rules={[{ required: true, message: '名' }]}>
                              <Input placeholder="名" onBlur={stage} />
                            </Item>
                          </Col>
                        </Row>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={10}>
                        <Item {...restField} label="证件号码" name={[name, 'certificateNum']} rules={[{ required: true, message: certificateType !== 2 ? '请上传证件照识别' : '请输入证件号码' }]}>
                          <Input placeholder={certificateType !== 2 ? '请上传证件照识别或者输入' : '请输入证件号码'} onBlur={stage} />
                        </Item>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={10}>
                        <Item {...restField} label="出生日期" name={[name, 'birthDate']} rules={[{ required: true, message: certificateType !== 2 ? '请上传证件照识别' : '请选择日期' }]}>
                          <DatePicker disabledDate={disabledDate} placeholder={certificateType !== 2 ? '请上传证件照识别或者选择' : '请选择日期'} style={{ width: '100%' }} onChange={stage} />
                        </Item>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={10}>
                        <Item {...restField} label="国籍" name={[name, 'nationality']} rules={[{ required: true, message: '请输入国籍' }]}>
                          <Input placeholder='请输入国籍' onBlur={stage} />
                        </Item>
                        <div className='des_suggest'>
                          请输入完整的联系地址，以便接受公司相关资料
                        </div>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? <Col span={10}>
                        <Item {...restField} label="联系电话" name={[name, 'phone']} rules={[{ required: true, message: '请输入正确的手机号', pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g") }]}>
                          <Input placeholder='请输入联系电话' onBlur={stage} />
                        </Item>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ?
                        type !== 2 && type !== 5 && type !== 7 ? <>
                          <Col span={10}>
                            <Item {...restField} label="区／市／省／州" name={[name, 'address', 0]} rules={[{ required: true, message: '请输入区／市／省／州' }]}>
                              <Input placeholder='请输入区／市／省／州' onBlur={stage} />
                            </Item>
                          </Col>
                          <Col span={10}>
                            <Item {...restField} label="街道／屋苑／地段／村" name={[name, 'address', 1]} rules={[{ required: true, message: '请输入街道／屋苑／地段／村' }]}>
                              <Input placeholder='请输入街道／屋苑／地段／村' onBlur={stage} />
                            </Item>
                          </Col><Col span={10}>
                            <Item {...restField} label="大 廈" name={[name, 'address', 2]} rules={[{ required: true, message: '请输入大 廈' }]}>
                              <Input placeholder='请输入大 廈' onBlur={stage} />
                            </Item>
                          </Col>
                          <Col span={10}>
                            <Item {...restField} label="室／樓／座 等" name={[name, 'address', 3]} rules={[{ required: true, message: '请输入室／樓／座 等' }]}>
                              <Input placeholder='请输入室／樓／座 等' onBlur={stage} />
                            </Item>
                          </Col>
                        </>
                          :
                          <Col span={10}>
                            <Item {...restField} label="联系地址" name={[name, 'address']} rules={[{ required: true, message: '请输入联系地址' }]}>
                              <Input placeholder='请输入联系地址' onBlur={stage} />
                            </Item>
                          </Col>
                        : null
                    }


                    {
                      hasNaturalPerson ? <Col span={10}>
                        <Item {...restField} label="职位" name={[name, 'position']} rules={[{ required: true, message: '请选择职位' }]}>
                          <Select placeholder='请选择职位' options={positionTypeMap} onBlur={stage} />
                        </Item>
                      </Col> : null
                    }
                    {
                      hasNaturalPerson ? null : <Col span={12}>
                        <Item label="公司执照类型" name={[name, 'licenseType']} rules={[{ required: true, message: '请选择公司执照类型' }]}>
                          <Select placeholder='请选择公司执照类型' options={licenseTypeMap} onChange={(e) => { licenseTypeMapChange(e, key) }} />
                        </Item>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null : <Col offset={1} span={20} style={{ marginTop: '-16px', marginLeft: '54px', marginBottom: '20px' }} >
                        <div className='des_suggest'>
                          <span>系统目前支持中国大陆身份证，中国护照以及中国工商营业执照的识别</span>
                        </div>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null :
                        <Row span={18}>
                          <Col span={10}>
                            {
                              licenseType === 1 ?
                                <Item labelCol={{ span: 8 }} {...restField} label="公司执照正面照" name={[name, 'frontCompanyLicense']} rules={[{ required: true, message: '请上传公司执照正面照' }]}>
                                  <Upload {...propsfile}
                                    onChange={file => fileChange(file, key, null, 'frontCompanyLicense')}
                                    onRemove={() => fileRemove(key, 'frontCompanyLicense')}>
                                    <Button type='link'>上传</Button>
                                  </Upload>
                                </Item> :
                                <Item labelCol={{ span: 8 }} {...restField} label="公司执照正面照" name={[name, 'frontCompanyLicense']} rules={[{ required: true, message: '请上传公司执照正面照' }]}>
                                  <Upload {...propsfile}
                                    onChange={file => fileChange(file, key, 'license', 'frontCompanyLicense')}
                                    onRemove={() => fileRemove(key, 'frontCompanyLicense')}
                                  >
                                    <Button type='link'>上传</Button>
                                  </Upload>
                                </Item>
                            }
                          </Col>
                          <Col span={6}>
                            <img alt=''
                              style={{ width: '78%', height: 'auto', margin: "0 0 20px -20px" }}
                              src={frontCompanyLicenseUrlImg}
                            />
                          </Col>
                        </Row>

                    }





                    {
                      hasNaturalPerson ? null : <Col span={10}>
                        <Item label="法人股东公司名称" name={[name, 'companyName']} rules={[{ required: true, message: '请输入法人股东公司名称' }]}>
                          <Input placeholder='请输入法人股东公司名称' onBlur={stage} />
                        </Item>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null : <Col span={10}>
                        <Item label="公司统一信用代码\公司编号" labelCol={{ span: 12 }} name={[name, 'companyNo']} rules={[{ required: true, message: '请输入公司统一信用代码\公司编号' }]}>
                          <Input style={{ width: 180 }} placeholder='请输入公司统一信用代码\公司编号' onBlur={stage} />
                        </Item>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null : <Col span={10}>
                        <Item label="公司注册地址" name={[name, 'registerAddress']} rules={[{ required: true, message: '请输入公司注册地址' }]}>
                          <Input placeholder='请输入公司注册地址' onBlur={stage} />
                        </Item>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null : <Col span={10}>
                        <Item {...restField} label="职位" name={[name, 'position']} rules={[{ required: true, message: '请选择职位' }]}>
                          <Select placeholder='请选择职位' options={positionTypeMap} onChange={stage} />
                        </Item>
                      </Col>
                    }
                    {
                      hasNaturalPerson ? null : <Col span={10}>
                        <Item {...restField} label="公司类型" name={[name, 'companyType']} rules={[{ required: true, message: '请输入公司类型' }]}>
                          <Select placeholder='请选择公司类型' options={companyTypeMap} onChange={stage} />
                        </Item>
                      </Col>
                    }
                    {
                      !hasPosition ? <Col span={10}>
                        <Item {...restField} label="占股比例" name={[name, 'shareProportion']} rules={[{ required: true, message: '请输入占股比例' }]}>
                          <InputNumber placeholder='请输入占股比例' style={{ width: '100%' }} addonAfter="%" />
                        </Item>
                      </Col> : null
                    }
                  </Row>
                </Col>
                {
                  fields.length > 1 ?
                    <Button className='deleteStyle' danger type='link' onClick={() => remove(name)}>删除</Button>
                    : null}
              </Row>
            })}
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                type='link'
                onClick={() =>
                  add({ shareholderType: 1, certificateType: 0, addressType: 0, licenseType: 0, position: 0 }, fields.length)
                }
                icon={<PlusOutlined />}
              >
                添加
              </Button>
            </Col>
          </Fragment >
        }}
      </List >
    </div>
  )

}

export default ShareHolder
