import React, { Fragment, useEffect, useState } from "react";
import "../index.css";
import {
  Divider,
  Row,
  Col,
  Descriptions,
  Form,
  Input,
  Checkbox,
  Upload,
  Select,
  Button,
  Radio,
  Card,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  requestBasicList,
  secretaryCompanyList,
} from "../../../services/api/registerInfo.request";
import ShareHolderChange from "./ShareHolderChange";
import ShareHolder from "./ShareHolder";
import OtherChange from "../component/otherChange";
const DItem = Descriptions.Item;
const { Item, List } = Form;
const options = [
  { label: "无变更项", value: 0 },
  { label: "公司名称变更", value: 1 },
  { label: "注册资本/币种变更", value: 2 },
  { label: "股东变更", value: 3 },
  { label: "董事变更", value: 4 },
  { label: "秘书地址变更", value: 5 },
  { label: "注册地址变更", value: 6 },
  { label: "其他变更", value: 7 },
];
const currencyOptions = [
  { label: "港币", value: 0 },
  { label: "人民币", value: 1 },
  { label: "美元", value: 2 },
];
const OneYearCheck = ({ formDom, stage, selectData }) => {
  const [secretaryCompanyOptions, setSecretaryCompanyOptions] = useState([]);
  const changeList = Form.useWatch(["register", "changeList"], formDom);
  const [fileProps, setFileProps] = useState({
    name: "file",
    accept: ".jpg,.jpeg,.png",
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  });
  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };
  const companyInfo = selectData.register.companyInfo;

  useEffect(() => {
    secretaryCompanyList().then((res) => {
      const list = res.data.map((item) => {
        return {
          ...item,
          label: item.address,
          value: item.id,
        };
      });
      setSecretaryCompanyOptions(list);
    });
  }, []);

  const secretaryCompanyChange = (e, type) => {
    secretaryCompanyOptions.map((item) => {
      if (e === item.id) {
        const address = item.address.split("|");
        formDom.setFieldValue(
          ["register", "secretaryCompany", "region"],
          "香港/HONG KONG"
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "district"],
          address[0]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "street"],
          address[1]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "building"],
          address[2]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "floor"],
          address[3]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "registrationCertificate"],
          item.registrationCertificate
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "email"],
          item.email
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "phone"],
          item.phone
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "companyName"],
          item.companyName
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "companyNameEn"],
          item.companyNameEn
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "licensePlate"],
          item.licensePlate
        );
        stage();
      }
    });
  };
  return (
    <Fragment>
      <div className="title_des">
        <span style={{ fontSize: "20px" }}>年审公司信息</span>
      </div>
      <Divider style={{ marginTop: 8 }} />
      <h3>请确认一下公司信息</h3>
      <p style={{ color: "#ff0000", marginBottom: "16px" }}>
        我们将根据一下公司信息生成法定文件进行政府审核
      </p>
      <Card
        style={{ borderColor: "#d1e8ff", marginBottom: "16px" }}
        bodyStyle={{ padding: "15px" }}
      >
        <Descriptions
          title="公司信息"
          column={2}
          labelStyle={{ color: "rgba(0, 0, 0, 0.88)", paddingLeft: "10px" }}
        >
          <DItem label="公司中文名称">
            {companyInfo["companyNameComplex"]}
          </DItem>
          <DItem label="公司英文名称">{companyInfo["companyNameEn"]}</DItem>
          <DItem label="注册资本">{companyInfo["registerCapital"]}</DItem>
          <DItem label="币种">
            {currencyOptions.map((item) => {
              if (companyInfo["currency"] === item.value) {
                return item.label;
              }
            })}
          </DItem>
          <DItem label="每股金额">{companyInfo["perAmount"]}</DItem>
          <DItem label="公司股份总数">{companyInfo["allCapital"]}</DItem>
          <DItem label="经营范围">{companyInfo["nature"]}</DItem>
          <DItem label="注册地址">{companyInfo["secretaryAddress"]}</DItem>
        </Descriptions>
      </Card>
      {companyInfo.peopleList.map((item) => {
        return (
          <Card
            style={{ borderColor: "#d1e8ff", marginBottom: "16px" }}
            bodyStyle={{ padding: "15px" }}
          >
            <Descriptions
              title="股东/董事信息"
              column={2}
              labelStyle={{ color: "rgba(0, 0, 0, 0.88)", paddingLeft: "10px" }}
            >
              {item.shareholderType == 1 && (
                <>
                  <DItem label="中文名">{item.name}</DItem>
                  <DItem label="英文名-姓">{item.surname}</DItem>
                  <DItem label="英文名-名">{item.otherNames}</DItem>
                  <DItem label="证件号码">{item.certificateNum}</DItem>
                  <DItem label="联系电话">{item.phone}</DItem>
                </>
              )}
              {item.shareholderType == 0 && (
                <>
                  <DItem label="公司编号">{item.companyNo}</DItem>
                  <DItem label="注册地址">{item.registerAddress}</DItem>
                </>
              )}
            </Descriptions>
          </Card>
        );
      })}
      <Row>
        <Col span={24}>
          <h3>
            <span style={{ color: "#ff0000" }}>*</span>
            公司信息是否存在需要变更的项目？
          </h3>
          <p style={{ color: "#999", marginBottom: "5px", fontSize: "13px" }}>
            如公司有需要变更的项目请勾选变更的项目，没有变更项目，请勾选无变更项，如果公司信息是错误的请联系客服。
          </p>
          <Item name={["register", "changeList"]}>
            <Checkbox.Group>
              <Space direction="vertical">
                {options.map((v) => (
                  <Checkbox value={v.value}>{v.label}</Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Item>
        </Col>
        <Item label="" name={["register", "companyInfo"]} hidden={true}></Item>
        {changeList && changeList.includes(1) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="公司名称变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <Col span={12}>
              <Item
                label="变更后公司名称"
                name={["register", "companyName"]}
                rules={[{ required: true, message: "请输入" }]}
                labelCol={{ span: 8 }}
              >
                <Input placeholder="请输入变更后公司名称" />
              </Item>
            </Col>
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={0} />
            </Col>
          </Fragment>
        )}
        {changeList && changeList.includes(2) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="注册资本变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <Col span={12}>
              <Item
                label="注册资本"
                name={["register", "registerCapital"]}
                rules={[{ required: true, message: "请输入注册资本" }]}
              >
                <Input placeholder="请输入注册资本" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="每股金额"
                name={["register", "perAmount"]}
                rules={[{ required: true, message: "请输入每股金额" }]}
              >
                <Input placeholder="请输入每股金额" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="公司股份总数"
                name={["register", "allCapital"]}
                rules={[{ required: true, message: "请输入公司股份总数" }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="币种"
                name={["register", "currency"]}
                rules={[{ required: true, message: "请选择币种" }]}
              >
                <Select
                  placeholder="请选择币种"
                  style={{ width: "100%" }}
                  options={currencyOptions}
                />
              </Item>
            </Col>
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={1} />
            </Col>
          </Fragment>
        )}
        {changeList && changeList.includes(3) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="股东变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <ShareHolderChange form={formDom} stage={stage} type="" />
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={2} />
            </Col>
          </Fragment>
        )}
        {changeList && changeList.includes(4) && (
          <Fragment>
            <ShareHolder form={formDom} />
            <OtherChange form={formDom} changDataIdx={3} />
          </Fragment>
        )}
        {changeList && changeList.includes(5) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="秘书地址变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <Col span={12}>
              <Item
                label="地区"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "region"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="区"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "district"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="街道"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "street"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="大厦"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "building"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="楼"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "floor"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="秘书公司商业登记证号"
                labelCol={{ span: 7 }}
                name={[
                  "register",
                  "secretaryCompany",
                  "registrationCertificate",
                ]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="秘书公司邮箱"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "email"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="秘书公司电话"
                labelCol={{ span: 7 }}
                name={["register", "secretaryCompany", "phone"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="请选择秘书公司地址"
                  style={{ width: "100%" }}
                  options={secretaryCompanyOptions}
                  onChange={secretaryCompanyChange}
                />
              </Item>
            </Col>
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={4} />
            </Col>
          </Fragment>
        )}
        {changeList && changeList.includes(6) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="注册地址变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <Col span={12}>
              <Item
                label="地区"
                labelCol={{ span: 7 }}
                name={["register", "registerAddress", 0]}
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="请输入" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="区"
                labelCol={{ span: 7 }}
                name={["register", "registerAddress", 1]}
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="请输入" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="街道"
                labelCol={{ span: 7 }}
                name={["register", "registerAddress", 2]}
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="请输入" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="大厦"
                labelCol={{ span: 7 }}
                name={["register", "registerAddress", 3]}
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="请输入" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="楼"
                labelCol={{ span: 7 }}
                name={["register", "registerAddress", 4]}
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="请输入" />
              </Item>
            </Col>
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={5} />
            </Col>
          </Fragment>
        )}
        {changeList && changeList.includes(7) && (
          <Fragment>
            <Col span={24}>
              <Item
                label="其他变更"
                labelCol={{ span: 24 }}
                style={{ paddingLeft: "10px" }}
              ></Item>
            </Col>
            <Col span={12}>
              <Item
                label="其他变更事项"
                rules={[{ required: true, message: "请输入" }]}
                labelCol={{ span: 8 }}
              >
                <Input placeholder="请输入其他变更事项" />
              </Item>
            </Col>
            <Col span={12}>
              <OtherChange form={formDom} changDataIdx={6} />
            </Col>
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
};

export default OneYearCheck;
