import request from '../request';

// 用户登录
export const userLogin = async (params) => {
  return request.post('/app/open/user/login', params)
};
// 获取验证码
export const getCode = async (params,type=0) => request.get(`/app/open/user/phone/code?mobile=${params}&type=${type}`)
// 手机验证码登录
export const codeLogin = async (params) => request.post(`/app/open/user/phone-login`,params)
// 忘记密码
export const forgetPassword = async (params) => request.post(`/app/open/user/forget-password`,params)