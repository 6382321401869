import React, { useState } from "react";
import { Button, Form, Radio, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Fragment } from "react";

const OtherChange = ({ form, changDataIdx }) => {
  const [type, setType] = useState(0);
  const [fileProps, setFileProps] = useState({
    name: "file",
    accept: ".jpg,.jpeg,.png",
    maxCount: 1,
    listType: "picture-card",
    beforeUpload: () => {
      return false;
    },
  });
  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };
  const change = (e) => {
    setType(e.target.value);
    form.setFieldValue(
      ["register", "changeDataBos", changDataIdx, "changeType"],
      e.target.value
    );
    if (e.target.value === 0) {
      setFileProps({
        name: "file",
        accept: ".jpg,.jpeg,.png",
        listType: "picture-card",
        maxCount: 1,
        beforeUpload: () => {
          return false;
        },
      });
    } else {
      setFileProps({
        name: "file",
        accept: ".pdf",
        maxCount: 1,
        beforeUpload: () => {
          return false;
        },
      });
    }
  };
  return (
    <Fragment>
      <Form.Item
        label="变更文件"
        name={["register", "changeDataBos", changDataIdx, "changeType"]}
        style={{ marginBottom: "5px" }}
        required={true}
      >
        <Radio.Group
          options={[
            { label: "图片文件", value: 0 },
            { label: "PDF文件", value: 1 },
          ]}
          defaultValue={0}
          onChange={change}
        />
      </Form.Item>
      <Form.Item
        name={["register", "changeDataBos", changDataIdx, "changeData"]}
        getValueFromEvent={normFile}
        valuePropName="fileList"
        wrapperCol={{ offset: 6 }}
        rules={[{ required: true, message: "请选择" }]}
      >
        <Upload {...fileProps}>
          <Button type="link">上传</Button>
        </Upload>
      </Form.Item>
    </Fragment>
  );
};
export default OtherChange;
