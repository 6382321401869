import React from 'react';
import { Row, Col, Form, Radio, Divider } from 'antd'
import "../index.css"
const { Item } = Form;
const Director = () => {
  return (<div>
    <div className="title_des" style={{ marginTop: 24 }}><span style={{ fontSize: '20px' }}>开户方式</span></div>
    <Divider style={{ marginTop: 8 }} />
    <Row>
      <Col span={24}>
        <Item label="开户方式" labelCol={{ span: 5 }} name={['account', 'openAccountType']} rules={[{ required: true, message: '请选择开户方式' }]}>
          <Radio.Group >
            <Radio value='仅开立香港账户'>仅开立香港账户</Radio>
            <Radio value='1+1（国内一般户/个人户+香港账户）'>1+1（国内一般户/个人户+香港账户）</Radio>
          </Radio.Group>
        </Item>
      </Col>
    </Row >
  </div>)
}

export default Director
