import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Divider, Radio, Select, Modal, Button, notification } from 'antd';
import { requestAddress, requestBasicList, requestToEn } from '../../../services/api/registerInfo.request';
import _ from 'lodash';
import "../index.css";

const { Item } = Form;

const NewCompany = ({ formDom, data, type }) => {
  const addressModeWatch = Form.useWatch(['register', 'addressMode'], formDom);
  const [registerAddressOptions, setRegisterAddressOptions] = useState([]);
  const [usNameEnd, setUSNameEnd] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chineseName, setChineseName] = useState('');
  const [englishName, setEnglishName] = useState('');
  const [planType] = useState(type);

  console.log(type)
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  useEffect(() => formDom.setFieldsValue({ registerAddress: '' }), [addressModeWatch])
  useEffect(() => {
    if (type === 2) {
      const { registerPlace } = data || {}
      if (registerPlace) requestAddress({ cityName: registerPlace }).then((res) => {
        const registerAddressOptions = [];
        _.forEach(res.data, (v) => {
          registerAddressOptions.push({ label: v.address, value: v.address })
        })
        setRegisterAddressOptions(registerAddressOptions);
      })
    } else if (type === 7) {
      setRegisterAddressOptions([{ label: "291 Brighton Road, South Croydon, United Kingdom, CR2 6EQ", value: "291 Brighton Road, South Croydon, United Kingdom, CR2 6EQ" }]);
    }


  }, [data])

  useEffect(() => {
    //  2 为 美国公司  7为英国公司
    if (type === 2) {
      requestBasicList({ code: 'englishSuffix_EN' }).then((res) => {
        const dictItemVOList = res.data.dictItemVOList;
        if (dictItemVOList.length) {
          const firstname = dictItemVOList[0].description;
          _.forEach(res.data.dictItemVOList, (v, k) => {
            usNameEnd.push({ label: v.description, value: v.description })
          })
          setUSNameEnd(usNameEnd);
          formDom.setFieldsValue({
            usCompanyNameEnd1: firstname,
            usCompanyNameEnd2: firstname,
            usCompanyNameEnd3: firstname,
          })
        }
      })
    } else if (type === 7) {
      const usNameEnd = [{ value: "LIMITED", label: "LIMITED", }, { value: "LTD", label: "LTD" }]
      setUSNameEnd(usNameEnd);
      formDom.setFieldsValue({
        usCompanyNameEnd1: "LIMITED",
      })
    }

  }, [])

  function doChToEn() {
    requestToEn(chineseName).then(res => {
      setEnglishName(res.data);
    })
  }


  function doCopyEnd() {
    navigator.clipboard.writeText(englishName).then(() => {
      notification.open({ message: '复制', description: '复制成功' });
      handleCancel();
    })
  }

  return (
    <div>
      <div className="title_des"><span style={{ fontSize: '20px' }}>新公司信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <Row>


        <Col span={4} style={{ textAlign: 'right' }}><span style={{ color: '#f00' }}>*</span>意向公司英文名称:</Col>

        {planType === 2 ? <Col span={20} offset={2} >
          <div className="des_suggest">
            <span>请输入意向公司英文名称</span>
          </div>
        </Col> : <Col span={20} offset={2} >
          <div className="des_suggest">
            <span>请输入注册公司英文名</span>
          </div>
        </Col>}


        <Col span={16} >
          <Row>
            <Col span={16}>
              <Item label={1} name={['register', 'companyNames', 0]}>
                <Input placeholder='请输入意向公司英文名称' />
              </Item>
            </Col>
            <Col span={6}>
              <Item name={['register', 'companyends', 0]}>
                <Select style={{ width: 140 }} options={usNameEnd} />
              </Item>
            </Col>
            {planType === 2 ?
              <>
                <Col span={16}>
                  <Item label={2} name={['register', 'companyNames', 1]}>
                    <Input placeholder='请输入意向公司英文名称' />
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name={['register', 'companyends', 1]}>
                    <Select style={{ width: 140 }} options={usNameEnd} />
                  </Item>
                </Col>
              </> : null}
            {planType === 2 ?
              <>
                <Col span={16}>
                  <Item label={3} name={['register', 'companyNames', 2]}>
                    <Input placeholder='请输入意向公司英文名称' />
                  </Item>
                </Col>
                <Col span={6}>
                  <Item name={['register', 'companyends', 2]}>
                    <Select style={{ width: 140 }} options={usNameEnd} />
                  </Item>
                </Col>
              </> : null}

          </Row>
        </Col>
        <Col span={8} className='translationWizard' >
          <p className='button' onClick={showModal}>
            <img alt="" src='https://scrm-product-1253467224.cos.ap-beijing.myqcloud.com/2540473940758812/2023/04/21/e0a084c8-373d-4a87-93a0-1f8e7b4bf5af.png' />
            翻译精灵
          </p>
        </Col>
        <Col span={8}>
          <Item labelCol={{ span: 9 }} label="注册地址" name={['register', 'addressMode']} rules={[{ required: true, message: '请输入注册地址' }]}>
            <Radio.Group>
              <Radio value={0}>机构提供</Radio>
              <Radio value={1}>自选地址</Radio>
            </Radio.Group>
          </Item>
        </Col>
        <Col span={16}>
          {
            addressModeWatch === 0 ? <Item name={['register', 'registerAddress']} rules={[{ required: true, message: '请输入机构提供' }]}>
              <Select placeholder='请选择机构提供' style={{ width: '100%' }} options={registerAddressOptions} />
            </Item> : null
          }
          {
            addressModeWatch === 1 ? <Item name={['register', 'registerAddress']} rules={[{ required: true, message: '请输入自选地址' }]}>
              <Input placeholder='请输入自选地址' />
            </Item> : null
          }
        </Col>
        <Col span={12}>
          <Item label="注册资本" name={['register', 'registerCapital']} rules={[{ required: true, message: '请输入注册资本' }]}>
            <Input placeholder='请输入注册资本' />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="每股金额" name={['register', 'perAmount']} rules={[{ required: true, message: '请输入每股金额' }]}>
            <Input placeholder='请输入每股金额' />
          </Item>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={6} style={{ textAlign: 'right' }}><span style={{ color: '#f00' }}>*</span>币种：</Col>
            <Col span={18}>{planType === 2 ? '美元' : '英镑'}</Col>
          </Row>
        </Col>
        <Col span={12}>
          <Item label="公司股份总数" name={['register', 'allCapital']} rules={[{ required: true, message: '请输入公司股份总数' }]}>
            <Input />
          </Item>
        </Col>
        <Col span={24} >
          <Item labelCol={{ span: 3 }} label="经营范围" name={['register', 'nature']} rules={[{ required: true, message: '请输入经营范围' }]}>
            <Input />
          </Item>
        </Col>
        <Col span={20} offset={3} style={{ marginTop: '-4px' }} >
          <div className="des_suggest">
            <span>温馨提示：常用的有：贸易、咨询服务、投资、电子、服装、物流。 如有多个词组，每个词组用逗号，分隔</span>
          </div>
        </Col>
      </Row >
      <Modal
        title={<div>
          <img alt="" src='https://scrm-product-1253467224.cos.ap-beijing.myqcloud.com/2540473940758812/2023/04/21/22b27c13-0a93-4322-90da-b27b02039f91.png' />
          <span>翻译精灵</span>
        </div>}
        footer={[]}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Row>
          <Col offset={3} span={15}>
            <Input placeholder='请输入中文' onChange={(e) => setChineseName(e.target.value)} />
          </Col>
          <Col span={3} style={{ marginLeft: '3%' }}>
            <Button type='primary' onClick={doChToEn}>翻译</Button>
          </Col>
          <Col offset={3} span={18}>
            <p style={{ padding: '8px 0', fontSize: '18px' }}>翻译结果</p>
          </Col>
          <Col offset={3} span={19}>
            <Input value={englishName} onChange={(e) => setEnglishName(e.target.value)} />
          </Col>
          <Col offset={8} span={8} style={{ marginTop: '32px' }}>
            <Button style={{ width: '100%' }} type='primary' onClick={doCopyEnd}>复制翻译结果</Button>
          </Col>
        </Row>
      </Modal >

    </div >)

}

export default NewCompany
