import React from 'react';
import { Row, Col, Form, Divider, Button, Upload } from 'antd'
import "../index.css"
const { Item } = Form;
const DatumUpload = ({ formDom, type }) => {
  const openByOurCompanyWatch = Form.useWatch(['account', 'isOpenByOurCompany'], formDom);
  const propsfile = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  }

  const normFile = (e) => {
    if (Array.isArray(e)) return e
    return e && e.fileList;
  }

  return (<div>
    <div className="title_des"><span style={{ fontSize: '20px' }}>资料上传</span></div>
    <Divider style={{ marginTop: 8 }} />
    {
      <React.Fragment>
        <Divider>开户公司资料上传</Divider>
        <Row>
          {openByOurCompanyWatch ? null : <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="注册证书CI"
              name={['account', 'ci']}
              rules={[{ required: true, message: '请上传注册证书CI' }]}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
          </Col>}
          {openByOurCompanyWatch ? null : <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="br"
              name={['account', 'br']}
              rules={[{ required: true, message: '请上传商业登记证BR' }]}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
          </Col>}
          {openByOurCompanyWatch ? null : <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="NARI/NNC1"
              name={['account', 'nari']}
              rules={[{ required: true, message: '请上传NARI/NNC1' }]}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
            <p className='tipsMessage'>公司成立1年内提供法团成立表（NNC1）</p>
            <p className='tipsMessage'>公司成立1年以上提供最新周年申报表（NAR1）</p>
          </Col>}
          <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="章程"
              name={['account', 'ma']}
              rules={[{ required: true, message: '请上传章程' }]}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
          </Col>
          <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="拟开户公司采购资料"
              name={['account', 'procurementData']}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
            <p className='tipsMessage'>提供近半年内的销售合同、对应发票或意向销售合同（1-2套）</p>
          </Col>
          <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="拟开户公司销售资料"
              name={['account', 'salesMaterials']}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
          </Col>
          <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="变更文件（如有）"
              name={['account', 'changeDocument']}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
            <p className='tipsMessage'>提供近半年内的销售合同、对应发票或意向销售合同（1-2套）</p>
          </Col>
          <Col span={10}>
            <Item
              labelCol={{ span: 10 }}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              label="近半年的公司流水（如有）"
              name={['account', 'turnover']}
            >
              <Upload {...propsfile}>
                <Button type='link'>上传</Button>
              </Upload>
            </Item>
          </Col>
        </Row>
      </React.Fragment>
    }
    <Divider>关联公司资料上传</Divider>
    <Row>
      <Col span={10}>
        <Item
          labelCol={{ span: 10 }}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          label="关联公司营业执照/注册证书"
          name={['account', 'license']}
          rules={[{ required: true, message: '请上传关联公司营业执照/注册证书' }]}
        >
          <Upload {...propsfile}>
            <Button type='link'>上传</Button>
          </Upload>
        </Item>
      </Col>
      <Col span={10}>
        <Item
          labelCol={{ span: 10 }}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          label="关联公司近半年的公司流水"
          name={['account', 'bankStatement']}
        >
          <Upload {...propsfile}>
            <Button type='link'>上传</Button>
          </Upload>
        </Item>
      </Col>
      <Col span={10}>
        <Item
          labelCol={{ span: 10 }}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          label="关联公司近一年财务报表(如有)"
          name={['account', 'report']}
        >
          <Upload {...propsfile}>
            <Button type='link'>上传</Button>
          </Upload>
        </Item>
      </Col>
      <Col span={10}>
        <Item
          labelCol={{ span: 10 }}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          label="关联公司销售资料"
          name={['account', 'salesMaterialsOne']}
          rules={[{ required: true, message: '请上传关联公司销售资料' }]}
        >
          <Upload {...propsfile}>
            <Button type='link'>上传</Button>
          </Upload>
        </Item>
        <p className='tipsMessage'>提供近半年内的销售合同、对应发票或意向销售合同（1-2套）</p>
        <p className='tipsMessage'>如涉及到海外业务，需提供与销售合同对应的提单及报关单</p>
      </Col>
      <Col span={10}>
        <Item
          labelCol={{ span: 10 }}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          label="关联公司采购资料"
          name={['account', 'procurementDataOne']}
          rules={[{ required: true, message: '请上传关联公司采购资料' }]}
        >
          <Upload {...propsfile}>
            <Button type='link'>上传</Button>
          </Upload>
        </Item>
        <p className='tipsMessage'>提供近半年内的销售合同、对应发票或意向销售合同（1-2套）</p>
        <p className='tipsMessage'>如涉及到海外业务，需提供与销售合同对应的提单及报关单</p>
      </Col>
    </Row>
  </div>)
}

export default DatumUpload
