import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Divider,
  Select,
  Space,
  Button,
  Cascader,
} from "antd";
import {
  requestBasicList,
  requestAppointment,
} from "../../../services/api/registerInfo.request";

import _ from "lodash";
import "../index.css";

const { Item } = Form;
const currencyOptions = [
  { label: "港币", value: 0 },
  { label: "人民币", value: 1 },
  { label: "美元", value: 2 },
];
const addressModeOptions = [
  { label: "秘书公司地址", value: 0 },
  { label: "自选地址", value: 1 },
];
const NewCompany = ({ formDom, type, data, stage }) => {
  const nnc1NatureModeWatch = Form.useWatch(
    ["register", "nnc1Nature"],
    formDom
  );
  const addressModeWatch = Form.useWatch(["register", "addressMode"], formDom);

  const registerCapitalWatch = Form.useWatch(
    ["register", "registerCapital"],
    formDom
  );
  const perAmountWatch = Form.useWatch(["register", "perAmount"], formDom);
  const [secretaryAddressOptions, setSecretaryAddressOptions] = useState([]);
  const [hasNameQuery, setHasNameQuery] = useState("");
  const [businessNatureOptions, setBusinessNatureOptions] = useState([]);
  const [secretaryCompanyOptions, setSecretaryCompanyOptions] = useState([]);
  useEffect(() => doSetSecretaryAddress(), []);

  useEffect(() => {
    if (type && data.orderId) doAppointment();
  }, [data]);
  useEffect(() => {
    const allNumber = registerCapitalWatch / (perAmountWatch || 1) || 0;
    formDom.setFieldsValue({ register: { allCapital: allNumber } });
  }, [registerCapitalWatch, perAmountWatch]);

  useEffect(() => {
    if (nnc1NatureModeWatch) {
      businessNatureOptions.map((item) => {
        if (item.value === nnc1NatureModeWatch[0]) {
          item.children.map((value) => {
            if (value.value === nnc1NatureModeWatch[1]) {
              formDom.setFieldsValue(
                ["register", "nnc1NatureCode"],
                value.itemText
              );
            }
          });
        }
      });
    }
  }, [nnc1NatureModeWatch, businessNatureOptions]);

  useEffect(() => {
    requestBasicList({ code: "businessScope" }).then((res) => {
      let list = [];
      res.data.dictItemVOList.map((item) => {
        const children = item.childDictItemVOList.map((value) => {
          return {
            ...value,
            label: value.description,
            value: value.id,
          };
        });
        list.push({
          ...item,
          children,
          label: item.description,
          value: item.id,
        });
      });
      setBusinessNatureOptions(list);
    });
  }, [data]);

  function doAppointment() {
    requestAppointment(data.orderId).then((res) => {
      if (res?.data?.status === "2") {
        formDom.setFieldsValue({
          companyNameComplex: res.data.companyName,
          companyNameEn: res.data.companyNameEn,
        });
      }
      setHasNameQuery(res.data.status);
    });
  }

  function doSetSecretaryAddress() {
    requestBasicList({ code: "secretaryAddress" }).then((res) => {
      const secretaryAddressOptions = [];
      _.forEach(res.data.dictItemVOList, (v, k) => {
        secretaryAddressOptions.push({
          label: v.description,
          value: v.description,
        });
      });
      setSecretaryAddressOptions(secretaryAddressOptions);
    });
  }

  function secretaryAddressChange(value) {
    stage();
  }
  function addressModeChange(value) {
    formDom.setFieldValue(
      ["register", "secretaryAddress"],
      value === 0 ? "" : []
    );
    setTimeout(() => {
      stage();
    }, 500);
  }

  const businessNatureChange = (e) => {
    console.log(data);
    businessNatureOptions.map((item) => {
      if (item.value === e[0]) {
        item.children.map((value) => {
          console.log(value)
          if (value.value === e[1]) {
            formDom.setFieldValue(
              ["register", "nnc1NatureCode"],
              value.itemText
            );
            formDom.setFieldValue(["register", "nnc1Nature"], value.label);
          }
        });
      }
    });

    setTimeout(() => {
      stage();
    }, 500);
  };

  return (
    <div>
      <div className="title_des">
        <span style={{ fontSize: "20px" }}>新公司信息</span>
      </div>
      <Divider style={{ marginTop: 8 }} />
      <Row style={{ position: "relative" }}>
        <Col span={10}>
          <Item
            label="公司中文名称（繁体）"
            labelCol={{ span: 8 }}
            name={["register", "companyNameComplex"]}
            rules={[{ required: true, message: "请输入公司中文名称（繁体）" }]}
          >
            <Input
              style={{ width: `${type ? "100%" : "100%"}` }}
              onBlur={stage}
            />
          </Item>
        </Col>
        <Col span={10}>
          <Item
            label="公司英文名称"
            labelCol={{ span: 8 }}
            name={["register", "companyNameEn"]}
            rules={[{ required: true, message: "请输入公司英文名称：" }]}
          >
            <Input onBlur={stage} />
          </Item>

        </Col>
      </Row>

      <div className="des_suggest" style={{ paddingLeft: "4.3%" }}>
        <span>常规注册资本为10000港币，可根据公司需求填写</span>
      </div>
      <Row>
        <Col span={10}>
          <Item
            label="币种"
            labelCol={{ span: 8 }}
            name={["register", "currency"]}
            rules={[{ required: true, message: "请选择币种" }]}
          >
            <Select
              placeholder="请选择币种"
              style={{ width: "100%" }}
              options={currencyOptions}
              onChange={stage}
            />
          </Item>
        </Col>
        <Col span={10}>
          <Item
            label="注册资本"
            labelCol={{ span: 8 }}
            name={["register", "registerCapital"]}
            rules={[{ required: true, message: "请输入注册资本" }]}
          >
            <InputNumber
              placeholder="请输入注册资本"
              precision={2}
              min={1}
              maxLength={13}
              style={{ width: "100%" }}
              onBlur={stage}
            />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Item
            label="每股金额"
            labelCol={{ span: 8 }}
            name={["register", "perAmount"]}
            rules={[{ required: true, message: "请输入每股金额" }]}
          >
            <InputNumber
              placeholder="请输入每股金额"
              precision={2}
              min={1}
              maxLength={13}
              style={{ width: "100%" }}
              onBlur={stage}
            />
          </Item>
        </Col>

        <Col span={10}>
          <Item
            label="公司股份总数"
            labelCol={{ span: 8 }}
            name={["register", "allCapital"]}
            rules={[{ required: true, message: "请输入公司股份总数" }]}
          >
            <InputNumber
              precision={0}
              style={{ width: "100%" }}
              onBlur={stage}
            />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={10}>
          <Item
            label="经营业务范围"
            labelCol={{ span: 8 }}
            name={["register", "nnc1Nature"]}
            rules={[{ required: true, message: "请选择范围" }]}
          >
            <Cascader
              options={businessNatureOptions}
              onChange={businessNatureChange}
              placeholder="Please select"
            />
          </Item>
        </Col>

        <Col span={10} style={{ display: "none" }}>
          <Item
            label="经营业务范围"
            labelCol={{ span: 8 }}
            name={["register", "nnc1NatureCode"]}
            rules={[{ required: true, message: "请选择经营范围" }]}
          >
            <Cascader
              options={businessNatureOptions}
              onChange={businessNatureChange}
              placeholder="请选择经营范围"
            />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <div className="des_suggest" style={{ paddingLeft: "2.3%" }}>
            <span>
              温馨提示：常用的有：贸易、咨询服务、投资、电子、服装、物流。
              如有多个词组，每个词组用逗号，分隔
            </span>
          </div>
          <Item
            labelCol={{ span: 4 }}
            label="经营范围描述"
            name={["register", "nature"]}
            rules={[{ required: true, message: "请输入经营范围描述" }]}
          >
            <Input placeholder="请输入经营范围" onBlur={stage} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Item
            labelCol={{ span: 4 }}
            label="注册地址"
            name={["register", "addressMode"]}
            rules={[{ required: true, message: "请选择注册地址" }]}
          >
            <Select
              style={{ width: "100%" }}
              options={addressModeOptions}
              onChange={addressModeChange}
            />
          </Item>
        </Col>
        {addressModeWatch ? (
          <>
            <Col span={10}>
              <Item
                labelCol={{ span: 8 }}
                label="区／市／省／州"
                name={["register", "secretaryAddress", 0]}
                rules={[{ required: true, message: "区／市／省／州" }]}
              >
                <Input placeholder="请输入区／市／省／州" onBlur={stage} />
              </Item>
            </Col>
            <Col span={10}>
              <Item
                labelCol={{ span: 8 }}
                label="街道／屋苑／地段／村"
                name={["register", "secretaryAddress", 1]}
                rules={[{ required: true, message: "请输入街道／屋苑／地段／村" }]}
              >
                <Input placeholder="请输入街道／屋苑／地段／村" onBlur={stage} />
              </Item>
            </Col>
            <Col span={10}>
              <Item
                labelCol={{ span: 8 }}
                label="大 廈"
                name={["register", "secretaryAddress", 2]}
                rules={[{ required: true, message: "请输入大 廈" }]}
              >
                <Input placeholder="请输入大 廈" onBlur={stage} />
              </Item>
            </Col>
            <Col span={10}>
              <Item
                labelCol={{ span: 8 }}
                label="室／樓／座 等"
                name={["register", "secretaryAddress", 3]}
                rules={[{ required: true, message: "请输入室／樓／座 等" }]}
              >
                <Input placeholder="请输入室／樓／座 等" onBlur={stage} />
              </Item>
            </Col>
          </>
        ) : (
          <Col span={20}>
            <Item
              labelCol={{ span: 4 }}
              label="秘书公司地址"
              name={["register", "secretaryAddress"]}
              rules={[{ required: true, message: "请选择秘书地址" }]}
            >
              <Select
                placeholder="请选择秘书地址"
                style={{ width: "100%" }}
                options={secretaryAddressOptions}
                onChange={secretaryAddressChange}
              />
            </Item>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default NewCompany;
