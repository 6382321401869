import React, { Fragment, useEffect, useRef, useState } from 'react';
import { RightOutlined, UserOutlined, LockOutlined , ArrowLeftOutlined } from '@ant-design/icons';
import '../../static/style/login/login.style.css';
import { useNavigate } from 'react-router-dom'
// import { userLogin } from '../../services/api/login.request'
import { userLogin , getCode , codeLogin , forgetPassword } from '../../services/api/login.request'
import { Button, Form, Input, notification } from 'antd';


const Login = () => {
  const allPasswd = useRef(true); // 允许密码登里
  const [mobile, setMobile] = useState(''); // 账号
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [ loginType , setLoginType ] = useState(2);// 1 : 账号密码登录2: 验证码登录
  const [ showCode , setShowCode ] = useState(false);// 1: 未获取验证码，2:获取验证码
  const [forgetPasswd , setForgetPasswd] = useState(false);// 忘记密码
  const [ loginTime , setLoginTime ] = useState(60); 
  const timer = useRef(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(()=>{
    if(forgetPasswd){
      form.setFieldsValue({phone:mobile,password:'',newPassword:'',code:''})
    }
  },[forgetPasswd])
  // 切换登录方式
  const changeLoginType = type => {
    setLoginType(type);
    setShowCode(false)
    if(type === 2){
      clearInterval(timer.current);
      setCode('')
    }
  }
  // 登录
  const handleLogIn = async () => {
    if (!mobile) {
      notification.open({ message: '登录异常', description: '请输入登录手机号' });
      return;
    }
    if (loginType === 2 && !code) {
      notification.open({ message: '登录异常', description: '请输入验证码' });
      return;
    }
    if (loginType === 1 && !password) {
      notification.open({ message: '登录异常', description: '请输入密码' });
      return;
    }
    const res = loginType === 2 ? await codeLogin({mobile , code}) : await userLogin({mobile,password});
    if (res.code == 200) {
      navigate('/registerInfo');
      window.localStorage.removeItem('submitData');
      window.localStorage.setItem('token', res.data.token)
    } else {
      notification.open({ message: '登录异常', description: res.desc });
    }
  }
  // 获取验证码
  const getLoginCode = async () => {
    if(showCode) return 
    const phoneNumber = forgetPasswd ? form.getFieldValue('mobile') : mobile;
    if (!phoneNumber || !(/^1[3,4,5,6,7,8,9][0-9]{9}$/.test(phoneNumber))) {
      notification.open({ message: '获取验证码异常', description: '请先输入正确手机号' });
      return;
    }
    const res = await getCode(phoneNumber,forgetPasswd?1:0);
    if(res.code === 200){
      notification.open({ message: '获取验证码成功', description: '请注意查收' });
      let num = 60;
      setShowCode(true)
      setLoginTime(num);
      timer.current = setInterval(() => {
        if(num === 0){
          setShowCode(false);
          clearInterval(timer.current)
        }else{
          num --
          setLoginTime(num);
        }
      },1000)
    }else {
      notification.open({ message: '获取验证码异常', description: res.desc });
    }
  }
  const validatePhone = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        reject('请输入手机号')
      } else if(!(/^1[3,4,5,6,7,8,9][0-9]{9}$/.test(value))){
        reject('请先输入正确手机号')
      }
      resolve('')
    })
  }
  const validateNewPasswd = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        reject('请输入')
      } else {
        const passwd = form.getFieldValue('password');
        if (!passwd) {
          reject('请先输入密码')
        } else if (passwd !== value) {
          reject('两次密码不一致')
        } else {
          resolve('')
        }
      }
    })
  }
  const submit = async (values) => {
    const res = await forgetPassword(values);
    if(res.code === 200){
      notification.open({ message: '密码修改成功', description: '即将重新登录' });
      setTimeout(()=>{
        setForgetPasswd(false)
        setMobile(values.mobile)
      },2000)
    }else{
      notification.error({ message: '错误', description: res.desc });
    }
  }
  return <div className='body'>
    <div className='container'>
      <div className='screen'>
        <div className='screen__content'>
          {!forgetPasswd && <form className='login'>
            <div className='login__field'>
              <Input value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="请输入手机号" prefix={<UserOutlined />} />
            </div>
            {loginType === 1 && 
            <Fragment>
              <div className='login__field'>
                <Input value={password} type='password' onChange={(e) => setPassword(e.target.value)} placeholder="请输入密码" prefix={<LockOutlined />} />
              </div>
              <div className="change_login_type">
                <span onClick={()=>changeLoginType(2)}>验证码登录</span>
                <span style={{float:'right'}} onClick={()=>setForgetPasswd(true)}>忘记密码</span>
              </div>
            </Fragment>}
            {loginType === 2 && 
            <Fragment>
              <div className='login__field'>
                <Input 
                  value={code}
                  onChange={(e) => setCode(e.target.value)} 
                  placeholder="请输入验证码" 
                  prefix={<LockOutlined />}
                  suffix={<span className="login_code" onClick={getLoginCode}>{
                    showCode ? loginTime + 's重新获取' : '获取验证码'
                  }</span>} />
              </div>
              {allPasswd.current && <div className="change_login_type" onClick={()=>changeLoginType(1)}>
                <span style={{float:'right'}}>账号密码登录</span>
              </div>}
            </Fragment>}
            <Button className='button login__submit' onClick={handleLogIn}>
              <span className='button__text'>登录</span>
              <RightOutlined className='button__icon' />
            </Button>
          </form>}
          {forgetPasswd && 
            <Form className="login" labelCol={{span:7}} form={form} onFinish={submit}>
              <Form.Item name="mobile" rules={[{required:true,validator:validatePhone}]}>
                <Input placeholder="请输入手机号" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item name="password" rules={[{required:true,message:'请输入新密码'}]} >
                <Input type="password" placeholder="请输入新密码" prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item name="confirmPassword" rules={[{required:true,validator:validateNewPasswd}]} >
                <Input type="password" placeholder="请再次输入新密码" prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item name="code" rules={[{required:true,message:'请输入验证码'}]} >
                <Input placeholder="请输入验证码" prefix={<LockOutlined />} suffix={<span className="login_code" onClick={getLoginCode}>{
                    showCode ? loginTime + 's重新获取' : '获取验证码'
                  }</span>} />
              </Form.Item>
              <Button htmlType="submit" className='button login__submit'>
                <span className='button__text'>确认修改</span>
                <RightOutlined className='button__icon' />
              </Button>
              <Button className='button login__submit' onClick={()=>{
                setForgetPasswd(false)
              }}>
                <span className='button__text'>返回登录</span>
                <RightOutlined className='button__icon' />
              </Button>
            </Form>
          }
        </div>
        <div className='screen__background'>
          <span className='screen__background__shape screen__background__shape4'></span>
          <span className='screen__background__shape screen__background__shape3'></span>
          <span className='screen__background__shape screen__background__shape2'></span>
          <span className='screen__background__shape screen__background__shape1'></span>
        </div>
      </div>
    </div>
  </div>
}

export default Login