import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, DatePicker, Radio, Divider, Select, Cascader } from 'antd';
import { requestBankRegisterlist } from '../../../services/api/registerInfo.request';
import { industryCategories } from '../../../utils/hskd.js';
import { country } from '../../../utils/country.js';
import moment from 'moment';
import _ from 'lodash';
import "../index.css";
const { Item } = Form;

const TestCompanyInfo = ({ formDom, stage, isHkType }) => {
  const openByOurCompanyWatch = Form.useWatch(['account', 'isOpenByOurCompany'], formDom);
  const [company, setCompany] = useState([]);

  const disabledDate = (_current) => {
    let current = _current.format('YYYY-MM-DD')
    return current && current > moment().endOf('day').format('YYYY-MM-DD')
  }

  console.log(industryCategories)

  useEffect(() => {
    requestBankRegisterlist().then(res => {
      const company = [];
      _.forEach(res.data, (v) => {
        company.push({ label: v.registeredCompanyName, value: v.id })
      })
      setCompany(company);
    })
  }, []);


  const areasChange = (e, key) => {
    country.forEach((item) => {
      if (item.name === e) {
        formDom.setFieldValue(
          ["account", key], item.id
        )
      }
    });


    stage(e, formDom.getFieldValue());
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const businessNatureChange = (e) => {
    console.log(e)

    industryCategories.forEach((item) => {
      if (item.value === e) {
        formDom.setFieldValue(
          ["account", "businessScope"], item.label
        )
      }
    });
    stage(e, formDom.getFieldValue());
  };

  return (
    <div>
      <div className="title_des"><span style={{ fontSize: '20px' }}>拟开户公司信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <Row>
        <Col span={24}>
          <Item labelCol={{ span: 4 }} label="是否我司注册公司" name={['account', 'isOpenByOurCompany']} rules={[{ required: true, message: '请选择是否我司注册公司' }]}>
            <Radio.Group>
              <Radio value={true}>在我司注册</Radio>
              <Radio value={false}>非我司注册</Radio>
            </Radio.Group>
          </Item>
        </Col>
        {
          openByOurCompanyWatch ? <Col span={24}>
            <Item labelCol={{ span: 4 }} label="开户公司选择" name={['account', 'companyId']} rules={[{ required: true, message: '请选择开户公司' }]}>
              <Select style={{ width: 220 }} placeholder='请选择开户公司' options={company} />
            </Item>
          </Col> : null
        }
        <Col span={10}>
          <Item labelCol={{ span: 8 }} label="拟开户公司名" name={['account', 'proposedCompanyName']} rules={[{ required: true, message: '请输入拟开户公司名' }]}>
            <Input placeholder='请输入拟开户公司名' onBlur={stage} />
          </Item>
        </Col>
        <Col span={10}>
          <Item labelCol={{ span: 8 }} label="拟开户公司注册编号" name={['account', 'proposedCompanyNo']} rules={[{ required: true, message: '请输入拟开户公司注册编号' }]}>
            <Input placeholder='请输入拟开户公司注册编号' onBlur={stage} />
          </Item>
        </Col>
        <Col span={10}>
          <Item labelCol={{ span: 8 }} label="拟开户成立日期" name={['account', 'establishmentDate']} rules={[{ required: true, message: '请输入拟开户成立日期' }]}>
            <DatePicker disabledDate={disabledDate} placeholder='请选择日期' style={{ width: '100%' }} onChange={stage} />
          </Item>
        </Col>
        {isHkType ? <Col span={10}>
          <Item label="经营业务性质" labelCol={{ span: 8 }} name={["account", "businessScopeCode"]} rules={[{ required: true, message: "请选择经营业务性质" }]}  >
            <Select options={industryCategories} onChange={(e) => businessNatureChange(e)} fieldNames={{ label: 'value', value: 'label' }} placeholder="请选择" />
          </Item>
        </Col> : openByOurCompanyWatch ? null : <Col span={10}>
          <Item labelCol={{ span: 8 }} label="拟开户公司经营范围" name={['account', 'businessScope']} rules={[{ required: true, message: '请输入拟开户公司经营范围' }]}>
            <Input placeholder='请输入拟开户公司经营范围' onBlur={stage} />
          </Item>
        </Col>
        }
        {isHkType ? <Col span={10}>
          <Item label="主要营业国家或地区" labelCol={{ span: 8 }} name={["account", "principalTradeZoneCode"]} rules={[{ required: true, message: "主要营业国家或地区" }]}  >
            <Select options={country} showSearch onSearch={onSearch} onChange={(e) => areasChange(e, 'principalTradeZone')} fieldNames={{ label: 'id ', value: 'name' }} placeholder="请选择" />
          </Item>
        </Col> :
          openByOurCompanyWatch ? null : <Col span={12}>
            <Item labelCol={{ span: 7 }} label="拟开户公司贸易往来国家" name={['account', 'principalTradeZone']} rules={[{ required: true, message: '请输入拟开户公司贸易往来国家' }]}>
              <Input placeholder='请输入拟开户公司贸易往来国家' onBlur={stage} />
            </Item>
          </Col>
        }

        {isHkType ?
          openByOurCompanyWatch ? null : <Col span={10}>
            <Item labelCol={{ span: 8 }} label="主要业务所在地" name={['account', 'mainBusinessLocationCode']} rules={[{ required: true, message: '请选择主要业务所在地' }]}>
              <Select options={country} showSearch onSearch={onSearch} onChange={(e) => areasChange(e, 'mainBusinessLocation')} fieldNames={{ label: 'name', value: 'id' }} placeholder="请选择" />
            </Item>
          </Col> : null
        }
        {isHkType ? <Col span={10}>
          <Item labelCol={{ span: 8 }} label="年营业额" name={['account', 'turnoverYear']} rules={[{ required: true, message: '请输入年营业额' }]}>
            <Input placeholder='请输入年营业额' type='number' onBlur={stage} />
          </Item>
        </Col> : null
        }

        {isHkType ? <Col span={10}>
          <Item labelCol={{ span: 14 }} label="其中一位实益拥有人有关业务上的年资" name={['account', 'experienceBusinessYearCount']} rules={[{ required: true, message: '请输入其中一位实益拥有人有关业务上的年资' }]}>
            <Input placeholder='请输入其中一位实益拥有人有关业务上的年资' type='text' maxLength={2} onBlur={stage} />
          </Item>
        </Col> : null
        }
      </Row>
    </div>

  )

}

export default TestCompanyInfo
