import React, { Fragment } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Divider, Button, Upload, Radio, Input, notification, Image as Img } from 'antd'
import "../index.css"
import { uploadServiceIdentity } from '../../../services/api/registerInfo.request';
const { Item, List } = Form;
const frontCertificateUrlImg = "https://minio.esailing.cn/gs-file/2023-04-25/0e14fc7bf6924a8ea9a56b139f1776e8/20230425-135135.png"
const backCertificateUrlImg = "https://minio.esailing.cn/gs-file/2023-04-25/4477d2b1cbee4188827dfa3bedfba36e/20230425-135154.png"

const BankUserInfo = ({ formDom }) => {
  const bankInfoWatch = Form.useWatch(['account', 'directorFile'], formDom);
  const propsfile = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  }
  const propsfileIdentity = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false
    }
  }
  const onChange = async (file, index, type, key) => {
    if (file.fileList.length === 0) return
    let formData = new FormData();
    formData.append('file', file.file);
    formData.append('type', type);
    const directorFile = JSON.parse(JSON.stringify(bankInfoWatch))
    const res = await uploadServiceIdentity(formData);
    directorFile[index] = {
      ...directorFile[index],
      [key]: res.code === 200 ? [{ url: res.data.imageUrl }] : []
    }
    console.log(formDom.getFieldsValue())
    formDom.setFieldValue('directorFile', directorFile)
    console.log(formDom.getFieldsValue())
    if (res.code !== 200) {
      notification.open({ message: '提示信息', description: res.desc });
    }
    return false
  }
  const onRemove = (index, type) => {
    const directorFile = JSON.parse(JSON.stringify(bankInfoWatch))
    directorFile[index] = {
      ...directorFile[index],
      [type]: []
    }
    formDom.setFieldValue('directorFile', directorFile)
  }
  const normFile = (e) => {
    if (Array.isArray(e)) return e
    return e && e.fileList;
  }
  return (<div>
    <div className="title_des"><span style={{ fontSize: '20px' }}>拟开户公司信息</span></div>
    <Divider style={{ marginTop: 8 }} />
    <div className='tips'>
      <p>注意：拟开户公司所有董事，以及持股20%以上的股东都需要上传证件资料</p>
      <p>1）身份证正反面</p>
      <p>2）护照（或通行证正反面）</p>
    </div>
    <List name={['account', 'directorFile']}>



      {(fields, { add, remove }) => {
        return <Fragment>
          {fields.map(({ key, name, ...restField }) => {
            return <Row className='userInfo' key={key}>
              <Col span={2} className='userNo'>
                <span>成员</span>
              </Col>



              <Col span={22} className='userDetails'>
                <Row>
                  <Col span={24}>
                    <Item labelCol={{ span: 4 }} label="银行账户授权签字人" name={[name, 'signatory']} rules={[{ required: true, message: '请选择是否我司注册公司' }]}>
                      <Radio.Group>
                        <Radio value={1}>是</Radio>
                        <Radio value={2}>否</Radio>
                      </Radio.Group>
                    </Item>
                  </Col>

                  <Col span={10}>
                    <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="身份证正面照" name={[name, 'frontCertificateUrl']} rules={[{ required: true, message: '请输入身份证正面照' }]}>
                      <Upload
                        {...propsfileIdentity}
                        onChange={file => onChange(file, key, 'idCardFront', 'frontCertificateUrl')}
                        onRemove={() => onRemove(key, 'frontCertificateUrl')}><Img src={frontCertificateUrlImg} preview={false} /></Upload>
                    </Item>
                  </Col>
                  <Col span={10}>
                    <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="身份证背面照" name={[name, 'backCertificateUrl']} rules={[{ required: true, message: '请输入身份证背面照' }]}>
                      <Upload
                        {...propsfileIdentity}
                        onChange={file => onChange(file, key, 'idCardBack', 'backCertificateUrl')}
                        onRemove={() => onRemove(key, 'backCertificateUrl')}><Img src={backCertificateUrlImg} preview={false} /></Upload>
                    </Item>
                  </Col>
                  <Col offset={3} span={10} style={{ marginTop: '-16px', marginBottom: '20px' }} >
                    <div className='des_suggest'>
                      <span style={{ marginLeft: '30%' }}>请上传身份证人像面照片</span>
                    </div>
                  </Col>
                  <Col offset={3} span={10} style={{ marginTop: '-16px', marginBottom: '20px' }} >
                    <div className='des_suggest'>
                      <span style={{ marginLeft: '30%' }}>请上传身份证国徽面照片</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Item {...restField} getValueFromEvent={normFile} valuePropName="fileList" label="护照/通行证正面照" name={[name, 'frontPassportUrl']} rules={[{ required: true, message: '请输入护照/通行证正面照' }]}>
                      <Upload {...propsfile}>
                        <Button type='link'>上传</Button>
                      </Upload>
                    </Item>
                  </Col>
                  <Col offset={3} span={21} style={{ marginTop: '-16px', marginBottom: '20px' }} >
                    <div className='des_suggest'>
                      <span style={{ marginLeft: '20%' }}>请上传护照/通行证正面照</span>
                    </div>
                  </Col>

                  {<Col span={8}>
                    <Item labelCol={{ span: 8 }} label="持股比例" name={[name, 'shareProportion']} rules={[{ required: true, message: '请输入持股比例' }]}>
                      <Input placeholder='请输入持股比例' type='number' />
                    </Item>
                  </Col>
                  }
                </Row>
              </Col>



              <Button className='deleteStyle' danger type='link' onClick={() => remove(name)}>删除</Button>
            </Row>
          })}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type='link' onClick={() => add({})} icon={<PlusOutlined />}>添加</Button>
          </Col>
        </Fragment >
      }}
    </List >
  </div>)

}

export default BankUserInfo
