import React, { useRef, Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Routes, Route } from "react-router-dom";
import Login from "./components/login/login";
import RegisterInfo from "./components/registerInfo/registerInfo";
import 'antd/dist/reset.css';
import 'dayjs/locale/zh-cn';


const App = () => {
  const locale = useRef(zhCN);
  return (
    <HashRouter>
      <ConfigProvider locale={locale.current}>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="registerInfo" element={<RegisterInfo />}></Route>
        </Routes>
      </ConfigProvider>
    </HashRouter>
  );
}

export default App
