import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Radio,
  Divider,
  Cascader,
  Input,
  Upload,
  Button,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import {
  requestBasicList,
  secretaryCompanyList,
} from "../../../services/api/registerInfo.request";
import "../index.css";

const { Item } = Form;
const currencyOptions = [
  { label: "港币", value: 0 },
  { label: "人民币", value: 1 },
  { label: "美元", value: 2 },
];

const AnnualCompany = ({ formDom, data, stage }) => {

  const [businessNatureOptions, setBusinessNatureOptions] = useState([]);
  const [secretaryCompanyOptions, setSecretaryCompanyOptions] = useState([]);
  const registerAddressType = Form.useWatch(
    ["register", "registerAddressType"],
    formDom
  );
  const propsfile = {
    name: "file",
    accept: ".jpg,.jpeg,.png",
    listType: "picture-card",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  };
  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };
  useEffect(() => {
    requestBasicList({ code: "businessScope" }).then((res) => {
      let list = [];
      res.data.dictItemVOList.map((item) => {
        if (
          data.register.businessNature &&
          item.description === data.register.businessNature.split("/")[0]
        ) {
          item.childDictItemVOList.map((value) => {
            if (
              value.description === data.register.businessNature.split("/")[1]
            ) {
              formDom.setFieldValue(
                ["register", "businessNatureList"],
                [item.id, value.id]
              );
            }
          });
        }
        const children = item.childDictItemVOList.map((value) => {
          return {
            ...value,
            label: value.description,
            value: value.id,
          };
        });
        list.push({
          ...item,
          children,
          label: item.description,
          value: item.id,
        });
      });
      setBusinessNatureOptions(list);
    });
    secretaryCompanyList().then((res) => {
      const list = res.data.map((item) => {
        return {
          ...item,
          label: item.address,
          value: item.id,
        };
      });
      setSecretaryCompanyOptions(list);
    });
  }, [data]);

  const disabledDate = (_current) => {
    let current = _current.format("YYYY-MM-DD");
    return current && current > moment().endOf("day").format("YYYY-MM-DD");
  };

  const businessNatureChange = (e) => {
    businessNatureOptions.map((item) => {
      if (item.value == e[0]) {
        item.children.map((value) => {
          if (value.value === e[1]) {
            formDom.setFieldValue(
              ["register", "businessNature"],
              item.label + "/" + value.label
            );
            formDom.setFieldValue(
              ["register", "businessNatureCode"],
              value.itemText
            );
          }
        });
      }
    });

    stage();
  };

  const secretaryCompanyChange = (e, type) => {
    secretaryCompanyOptions.map((item) => {
      if (e === item.id) {
        if (type === 1) {
          formDom.setFieldValue(["register", "registerAddress"], []);
        } else {
          formDom.setFieldValue(
            ["register", "registerAddress"],
            ["香港/HONG KONG"].concat(item.address.split("|"))
          );
        }
        const address = item.address.split("|");
        formDom.setFieldValue(
          ["register", "secretaryCompany", "region"],
          "香港/HONG KONG"
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "district"],
          address[0]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "street"],
          address[1]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "building"],
          address[2]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "floor"],
          address[3]
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "registrationCertificate"],
          item.registrationCertificate
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "email"],
          item.email
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "phone"],
          item.phone
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "companyName"],
          item.companyName
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "companyNameEn"],
          item.companyNameEn
        );
        formDom.setFieldValue(
          ["register", "secretaryCompany", "licensePlate"],
          item.licensePlate
        );
        stage();
      }
    });
  };
  return (
    <>
      <div className="title_des">
        <span style={{ fontSize: "20px" }}>公司信息</span>
      </div>
      <Divider style={{ marginTop: 8 }} />
      <Row>
        <Col span={12}>
          <Item
            label="经营业务性质"
            labelCol={{ span: 7 }}
            name={["register", "businessNatureList"]}
            rules={[{ required: true, message: "请选择经营业务性质" }]}
          >
            <Cascader
              options={businessNatureOptions}
              onChange={businessNatureChange}
              placeholder="Please select"
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司CI"
            labelCol={{ span: 7 }}
            getValueFromEvent={normFile}
            valuePropName="fileList"
            name={["register", "ci"]}
            rules={[{ required: true, message: "请上传公司CI" }]}
          >
            <Upload {...propsfile}>
              <Button type="link">上传</Button>
            </Upload>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司注册编号"
            labelCol={{ span: 7 }}
            name={["register", "companyNo"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司中文名称"
            labelCol={{ span: 7 }}
            name={["register", "companyName"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司英文名称"
            labelCol={{ span: 7 }}
            name={["register", "companyEnName"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司注册资本"
            labelCol={{ span: 7 }}
            name={["register", "registerCapital"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="每股金额"
            labelCol={{ span: 7 }}
            name={["register", "perAmount"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司股份总数"
            labelCol={{ span: 7 }}
            name={["register", "allCapital"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="币种"
            labelCol={{ span: 7 }}
            name={["register", "currency"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择币种"
              style={{ width: "100%" }}
              options={currencyOptions}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="公司成立日期"
            labelCol={{ span: 7 }}
            name={["register", "establishmentDate"]}
            rules={[{ required: true, message: "" }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              placeholder={"请选择日期"}
              style={{ width: "100%" }}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="BR"
            labelCol={{ span: 7 }}
            getValueFromEvent={normFile}
            valuePropName="fileList"
            name={["register", "br"]}
            rules={[{ required: true, message: "" }]}
          >
            <Upload {...propsfile}>
              <Button type="link">上传</Button>
            </Upload>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="商业登记证号码"
            labelCol={{ span: 7 }}
            name={["register", "businessRegistNo"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="上一年度周年申报表"
            labelCol={{ span: 7 }}
            getValueFromEvent={normFile}
            valuePropName="fileList"
            name={["register", "declarationForm"]}
            rules={[{ required: true, message: "" }]}
          >
            <Upload {...propsfile} maxCount={3}>
              <Button type="link">上传</Button>
            </Upload>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="上一年度变更资料"
            labelCol={{ span: 7 }}
            name={["register", "changeDataFile"]}
            getValueFromEvent={normFile}
            valuePropName="fileList"
          >
            <Upload {...propsfile}>
              <Button type="link">上传</Button>
            </Upload>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="注册地址"
            labelCol={{ span: 7 }}
            name={["register", "registerAddressType"]}
            rules={[{ required: true, message: "" }]}
          >
            <Radio.Group>
              <Radio
                value={0}
                onChange={() => {
                  secretaryCompanyChange(secretaryCompanyOptions[0].id);
                }}
              >
                秘书地址
              </Radio>
              <Radio
                value={1}
                onChange={() => {
                  secretaryCompanyChange(secretaryCompanyOptions[0].id, 1);
                }}
              >
                自选地址
              </Radio>
            </Radio.Group>
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="地区"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "region"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="区"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "district"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="街道"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "street"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="大厦"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "building"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="楼"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "floor"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="秘书公司商业登记证号"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "registrationCertificate"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="秘书公司邮箱"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "email"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 1}>
          <Item
            label="秘书公司电话"
            labelCol={{ span: 7 }}
            name={["register", "secretaryCompany", "phone"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              placeholder="请选择秘书公司地址"
              style={{ width: "100%" }}
              options={secretaryCompanyOptions}
              onChange={secretaryCompanyChange}
            />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 0}>
          <Item
            label="地区"
            labelCol={{ span: 7 }}
            name={["register", "registerAddress", 0]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 0}>
          <Item
            label="区"
            labelCol={{ span: 7 }}
            name={["register", "registerAddress", 1]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 0}>
          <Item
            label="街道"
            labelCol={{ span: 7 }}
            name={["register", "registerAddress", 2]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 0}>
          <Item
            label="大厦"
            labelCol={{ span: 7 }}
            name={["register", "registerAddress", 3]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12} hidden={registerAddressType === 0}>
          <Item
            label="楼"
            labelCol={{ span: 7 }}
            name={["register", "registerAddress", 4]}
            rules={[{ required: true, message: "" }]}
          >
            <Input placeholder="请输入" />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default AnnualCompany;
