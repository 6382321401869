import React, { Fragment } from 'react'
import { Form , Row , Col, Select , Divider , Input } from 'antd'
const { Item } = Form
const JobInfo = () => {
  return (
    <Fragment>
      <div className="title_des"><span style={{ fontSize: '20px' }}>职务信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <Row>
        <Col span={12} className="has-explain">
          <Item label="董事长" name="" rules={[{required:true,message:'请选择'}]}>
            <Select options={[]} />
          </Item>
          <p class="explain">必须是公司董事之一</p>
        </Col>
        <Col span={12} className="has-explain">
          <Item label="财务长" name="" rules={[{required:true,message:'请选择'}]}>
            <Select options={[]} />
          </Item>
          <p class="explain">可为公司任一股东或董事担任</p>
        </Col>
        <Col span={12} className="has-explain">
          <Item label="董事秘书长" name="" rules={[{required:true,message:'请选择'}]}>
            <Select options={[]} />
          </Item>
          <p class="explain">可为公司任一股东或董事担任</p>
        </Col>
      </Row>
      <div className="title_des"><span style={{ fontSize: '20px' }}>联系人信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <Row>
        <Col span={12}>
          <Item label="联系人姓名" name="" rules={[{required:true,message:'请输入'}]}>
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="联系人电话" name="" rules={[{required:true,message:'请输入'}]}>
            <Input placeholder="请输入" />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="联系人邮箱" name="" rules={[{required:true,message:'请输入'}]}>
            <Input placeholder="请输入" />
          </Item>
        </Col>
      </Row>
    </Fragment>
  )
}
export default JobInfo