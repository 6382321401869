import React, { Fragment, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Divider, Button, Radio, Input } from 'antd';
import { requestBasicList } from '../../../services/api/registerInfo.request';

import "../index.css"
const { Item, List } = Form;
const BankUserInfo = ({ formDom, stage }) => {
  const knowWayWatch = Form.useWatch('knowWay', formDom);
  const [learnRoadList, setLearnRoadList] = useState([]);
  const [knowWayName, setKnowWayName] = useState([]);
  const [description, setDescription] = useState({});

  useEffect(() => doSetLearnRoadList(), []);
  function doSetLearnRoadList() {
    requestBasicList({ code: "learnRoad" }).then(res => {
      const list = res.data.dictItemVOList.map(v => v.description);
      const description = {};
      res.data.dictItemVOList.forEach(item => {
        description[item.description] = item.childDictItemVOList?.map(v => ({ label: v.description, value: v.description }))
      });
      setKnowWayName(list);
      setDescription(description);
      setLearnRoadList(res.data.dictItemVOList);
    })
  }
  return (<div>
    <div className="title_des"><span style={{ fontSize: '20px' }}>联系人信息</span></div>
    <Divider style={{ marginTop: 8 }} />
    <div className='tips'>
      <p>填写至少一位实际参与公司运营的董事联系方式，以便后续银行对接。</p>
    </div>
    <List name={['account', 'contacts']}>
      {(fields, { add, remove }) => {
        return <Fragment>
          {fields.map(({ key, name, ...restField }) => {
            return <Row className='userInfo' key={key}>
              <Col span={2} className='userNo'>
                <span>成员</span>
              </Col>
              <Col span={22} className='userDetails'>
                <Row>
                  <Col span={10}>
                    <Item {...restField} labelCol={{ span: 10 }} label="拟开户公司联系人姓名" name={[name, 'name']} rules={[{ required: true, message: '请输入拟开户公司联系人姓名' }]}>
                      <Input onBlur={stage} placeholder='请输入拟开户公司联系人姓名' />
                    </Item>
                  </Col>
                  <Col offset={2} span={10}>
                    <Item {...restField} labelCol={{ span: 10 }} label="拟开户公司联系人手机" name={[name, 'phone']} rules={[{ required: true, message: '请输入拟开户公司联系人手机' }]}>
                      <Input onBlur={stage} placeholder='请输入拟开户公司联系人手机' />
                    </Item>
                  </Col>
                  <Col span={10}>
                    <Item {...restField} labelCol={{ span: 10 }} label="拟开户公司联系人邮箱" name={[name, 'email']} rules={[{ required: true, message: '请输入拟开户公司联系人邮箱' }]}>
                      <Input onBlur={stage} placeholder='请输入拟开户公司联系人邮箱' />
                    </Item>
                  </Col>
                  <Col offset={2} span={10}>
                    <Item {...restField} labelCol={{ span: 10 }} label="拟开户公司联系人地址" name={[name, 'address']} rules={[{ required: true, message: '拟开户公司联系人地址' }]}>
                      <Input onBlur={stage} placeholder='拟开户公司联系人地址' />
                    </Item>
                  </Col>
                </Row>
              </Col>
              <Button className='deleteStyle' danger type='link' onClick={() => remove(name)}>删除</Button>
            </Row>
          })}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type='link' onClick={() => add()} icon={<PlusOutlined />}>添加</Button>
          </Col>
        </Fragment >
      }}
    </List >
    {/* <Col span={24}>
      <Item label=" 请您选择得知本公司途径" labelCol={{ span: 5 }} name="knowWay" rules={[{ required: true, message: '请选择得知本公司途径' }]}>
        <Radio.Group >
          {
            learnRoadList.map((v) => {
              return <Radio key={v.id} value={v.description}>{v.description}</Radio>
            })
          }
          <Radio value='再办理'>再办理</Radio>
          <Radio value='老客户介绍'>老客户介绍</Radio>
        </Radio.Group>
      </Item>
    </Col> */}
    <Row style={{ width: '100%', marginTop: -16 }}>
      {
        knowWayName.includes(knowWayWatch) && description[knowWayWatch] ? <Col offset={5} span={24}>
          <Item name='internetSource'>
            <Radio.Group options={description[knowWayWatch]} />
          </Item>
        </Col> : null
      }
      {
        knowWayWatch === '再办理' ? <React.Fragment>
          <Col offset={2} span={6}>
            <Item label="曾办公司名1" labelCol={{ span: 10 }} name="formerCompanyNameA" rules={[{ required: true, message: '请输入曾办公司名' }]}>
              <Input placeholder='请输入曾办公司名' />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="曾办公司名2" labelCol={{ span: 10 }} name="formerCompanyNameB" rules={[{ required: true, message: '请输入曾办公司名' }]}>
              <Input placeholder='请输入曾办公司名' />
            </Item>
          </Col>
        </React.Fragment> : null
      }
      {
        knowWayWatch === '老客户介绍' ? <React.Fragment>
          <Col offset={2} span={6}>
            <Item label="介绍人姓名" labelCol={{ span: 10 }} name="introducerName" rules={[{ required: true, message: '请输入介绍人姓名' }]}>
              <Input placeholder='请输入介绍人姓名' />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="介绍人电话" labelCol={{ span: 10 }} name="introducerMobile" rules={[{ required: true, message: '请输入介绍人电话' }]}>
              <Input placeholder='请输入介绍人电话' />
            </Item>
          </Col>
          <Col span={8}>
            <Item label="在我司注册名称" labelCol={{ span: 10 }} name="introducerCompanyName" rules={[{ required: true, message: '请输入在我司注册名称' }]}>
              <Input placeholder='请输入在我司注册名称' />
            </Item>
          </Col>
        </React.Fragment> : null
      }
    </Row>
  </div>)

}

export default BankUserInfo
