import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Radio, Divider } from 'antd';
import { requestBasicList } from '../../../services/api/registerInfo.request';
import "../index.css"
const { Item } = Form;
const ContactPerson = ({ formDom , stage }) => {
	const knowWayWatch = Form.useWatch(['register', 'knowWay'], formDom);
	const [learnRoadList, setLearnRoadList] = useState([]);
	const [knowWayName, setKnowWayName] = useState([]);
	const [description, setDescription] = useState({});

	useEffect(() => doSetLearnRoadList(), []);
	function doSetLearnRoadList() {
		requestBasicList({ code: "learnRoad" }).then(res => {
			const list = res.data.dictItemVOList.map(v => v.description);
			const description = {};
			res.data.dictItemVOList.forEach(item => {
				description[item.description] = item.childDictItemVOList?.map(v => ({ label: v.description, value: v.description }))
			});
			setKnowWayName(list);
			setDescription(description);
			setLearnRoadList(res.data.dictItemVOList);
		})
	}
	return (
		<div>
			<div className="title_des"><span style={{ fontSize: '20px' }}>联系人信息</span></div>
			<Divider style={{ marginTop: 8 }} />
			<Row>
				<Col span={12}>
					<Item label="联系人姓名" labelCol={{ span: 7 }} name={['register', 'urgentContactName']} rules={[{ required: true, message: '请输入联系人姓名' }]}>
						<Input placeholder='请输入联系人姓名' onBlur={stage} />
					</Item>
				</Col>
				<Col span={12}>
					<Item label="联系人电话" labelCol={{ span: 7 }} name={['register', 'urgentContactPhone']} rules={[{ required: true, message: '请输入正确的手机号',pattern:new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g")}]}>
						<Input placeholder='请输入联系人电话' onBlur={stage} />
					</Item>
				</Col>
				<Col span={12}>
					<Item label="联系人邮箱" labelCol={{ span: 7 }} name={['register', 'urgentContactEmail']} rules={[{ required: true, message: '请输入正确的邮箱',pattern:new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "g")}]}>
						<Input placeholder='请输入联系人邮箱' onBlur={stage} />
					</Item>
				</Col>
				<Row style={{ width: '100%', marginTop: -16 }}>
					{
						knowWayName.includes(knowWayWatch) && description[knowWayWatch] ? <Col offset={5} span={24}>
							<Item name={['register', 'internetSource']}>
								<Radio.Group options={description[knowWayWatch]} />
							</Item>
						</Col> : null
					}
					{
						knowWayWatch === '再办理' ? <React.Fragment>
							<Col offset={2} span={6}>
								<Item label="曾办公司名1" labelCol={{ span: 10 }} name={['register', 'formerCompanyNameA']} rules={[{ required: true, message: '请输入曾办公司名' }]}>
									<Input placeholder='请输入曾办公司名' />
								</Item>
							</Col>
							<Col span={6}>
								<Item label="曾办公司名2" labelCol={{ span: 10 }} name={['register', 'formerCompanyNameB']} rules={[{ required: true, message: '请输入曾办公司名' }]}>
									<Input placeholder='请输入曾办公司名' />
								</Item>
							</Col>
						</React.Fragment> : null
					}
					{
						knowWayWatch === '老客户介绍' ? <React.Fragment>
							<Col offset={2} span={6}>
								<Item label="介绍人姓名" labelCol={{ span: 10 }} name={['register', 'introducerName']} rules={[{ required: true, message: '请输入介绍人姓名' }]}>
									<Input placeholder='请输入介绍人姓名' />
								</Item>
							</Col>
							<Col span={6}>
								<Item label="介绍人电话" labelCol={{ span: 10 }} name={['register', 'introducerMobile']} rules={[{ required: true, message: '请输入介绍人电话' }]}>
									<Input placeholder='请输入介绍人电话' />
								</Item>
							</Col>
							<Col span={8}>
								<Item label="在我司注册名称" labelCol={{ span: 10 }} name={['register', 'introducerCompanyName']} rules={[{ required: true, message: '请输入在我司注册名称' }]}>
									<Input placeholder='请输入在我司注册名称' />
								</Item>
							</Col>
						</React.Fragment> : null
					}
				</Row>
			</Row >
		</div>)

}

export default ContactPerson
