import request from "../request";

// 香港公司注册
export const doRegisterSave = async (params) => {
  return request.post("/app/customer/register/save", params);
};

// 香港公司暂存
export const doRegisterStage = async (params) => {
  return request.post("/app/customer/register/stage", params);
};
// 香港公司+银行开户
export const doRegisterAndOpen = async (params) => {
  return request.post("/app/registerAndOpen/create", params);
};

// 银行开户
export const doBankAccount = async (params) => {
  return request.post("/app/account/create", params);
};

//获取订单order
export const getOrderList = async (params) => {
  return request.post("/app/customer/order/condition-list", params);
};

//获取订单数据详情
export const getOrderDetail = async (orderId) => {
  return request.get(`/app/customer/register/detail/${orderId}`);
};

//获取订单数据详情
export const getBankDetail = async (orderId) => {
  return request.post(`/app/account/get/${orderId}`);
};

// 上传文件
export const uploadService = async (params) => {
  return request.post("/app/file/upload", params);
};

// 上传身份证正反面校验
export const uploadServiceIdentity = async (params) => {
  return request.post("/app/credentials/identify", params);
};

// 注册公司取名结果
export const requestGetNameResult = async (orderId) => {
  return request.get(`/app/customer/register/naming/result/${orderId}`);
};

// 注册公司取名结果
export const requestRegisterNaming = async (params) => {
  return request.post("/app/customer/register/naming", params);
};

// 中文转英文接口
export const requestToEn = (params) => {
  return request.get(`/app/crm/toEn?name=${params}`);
};

// 中文转中文繁体接口
export const requestToTch = (params) => {
  return request.get(`/app/crm/toTch?name=${params}`);
};

// 中文转中文繁体接口
export const requestToTpy = (params) => {
  return request.get(`/app/crm/toPinyin?name=${params}`);
};

export const requestBasicList = (params) => {
  return request.post("/app/basic/list", params);
};

export const requestAddress = (params) => {
  return request.post("/app/address/list", params);
};

export const requestAppointment = (orderId) => {
  return request.get(`/app/customer/company/appointment/${orderId}`);
};
// 查询已注册的公司
export const requestBankRegisterlist = () => {
  return request.get("/app/customer/bank-register/list/completed");
};

export const postRegisterAndOpen = (orderId) => {
  return request.post(`/app/registerAndOpen/${orderId}`);
};

// 获取年审填写资料详情
export const getAnnualRegister = (orderId) => {
  return request.get(`/app/customer/register/annual/${orderId}`);
};
// 获取秘书公司列表
// /v1/app/secretaryCompany/list
export const secretaryCompanyList = () => {
  return request.post(`/app/secretaryCompany/list`);
};
// 年审资料填写
export const setAnnualBranch = (params) => {
  return request.post(`/app/customer/annualBranch`, params);
};

// 查询订单详情
export const getOrderDetailInfo = (id) => {
  return request.get(`/app/customer/order/info/${id}`);
};
