export const industryCategories = [
  {
    label: "Construction",
    value: "建筑",
  },
  {
    label: "Education",
    value: "教育",
  },
  {
    label: "FinancialAndInsurance",
    value: "金融和保险",
  },
  {
    label: "HealthAndSocialWork",
    value: "健康和社会工作",
  },
  {
    label: "HotelsAndRestaurants",
    value: "酒店和餐厅",
  },
  {
    label: "ManufacturingConsumerGoods",
    value: "制造业 - 消费品，例如 家具，玩具",
  },
  {
    label: "ManufacturingOthers",
    value: "制造业 - 其他",
  },
  {
    label: "OtherBusinessActivities",
    value: "其他商业活动",
  },
  {
    label: "OtherCommunitySocialAndPersonalServiceActivities",
    value: "其他社区，社会和个人服务活动",
  },
  {
    label: "RealEstateAndRenting",
    value: "房地产和租赁",
  },

  {
    label: "RetailSale",
    value: "零售",
  },
  {
    label: "TransportStorageAndCommunications",
    value: "运输，仓储和通讯",
  },
  {
    label: "WholesaleTrading",
    value: "批发",
  },

  {
    label: "Others",
    value: "其他",
  },
];
