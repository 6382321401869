import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, Divider } from 'antd';
import _ from 'lodash';
import "../index.css";
const { Item } = Form;
const NewCompany = ({ formDom }) => {
  const shareholderTypeWatch = Form.useWatch(['register', 'peopleList'], formDom);
  const chairmanWatch = Form.useWatch(['register', 'chairman'], formDom);
  const [chairmanArray, setChairmanArray] = useState([])
  useEffect(() => {
    const chairmanArray = [];
    let hasExist = [];
    _.forEach(shareholderTypeWatch, (item) => {
      const { name, shareholderType, position } = item;
      if (shareholderType === 1 && position !== 1 && name) {
        hasExist.push(name);
        chairmanArray.push({ label: name, value: name });
      }
    });
    if (!hasExist.includes(chairmanWatch)) formDom.setFieldsValue({ register: { chairman: '' } });
    setChairmanArray(chairmanArray);
  }, [shareholderTypeWatch])
  return (
    <div>
      <div className="title_des"><span style={{ fontSize: '20px' }}>职务信息</span></div>
      <Divider style={{ marginTop: 8 }} />
      <Row>
        <Col span={8}>
          <Item label="董事长" name={['register', 'chairman']} rules={[{ required: true, message: '请选择董事长' }]}>
            <Select placeholder="请选择董事长" options={chairmanArray} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="财务长" name={['register', 'treasurer']} rules={[{ required: true, message: '请选择财务长' }]}>
            <Select placeholder="请选择财务长" options={chairmanArray} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="秘书" name={['register', 'secretary']} rules={[{ required: true, message: '请选择董事长' }]}>
            <Select placeholder="请选择秘书" options={chairmanArray} />
          </Item>
        </Col>
      </Row>
    </div>
  )

}

export default NewCompany
